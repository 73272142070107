import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {DOCUMENT} from '@angular/common';
import {CustomizerService} from '../services/customizer.service';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {LISTITEMS} from '../data/template-search';
import {Router} from '@angular/router';
import {LocalStorageService} from '../storage/local-storage.service';
import {AuthService} from '../auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

const moment = require('moment')

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  closeResult = '';

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();
  userData: any;
  pharmacyList: any[] = [];

  public config: any = {};
  submitted = false;
  defaultPharmacy: string;
  platform: any;
  user: any;


  constructor(public translate: TranslateService, private storage: LocalStorageService,
              private layoutService: LayoutService,
              private modalService: NgbModal, private toastr: ToastrService,
              private router: Router, private authService: AuthService,
              private configService: ConfigService, private cdr: ChangeDetectorRef) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.pharmacySwitch.subscribe((resp) => {
      this.defaultPharmacy = resp;
    })
    this.authService.fetch_user_detail(this.userData).then((resp: any) => {
      if (resp.status === 404) {
        this.authService.logout();
        this.router.navigate(['/pages/launch']);
      } else if (resp.status === 401) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.authService.logout();
        this.router.navigate(['/pages/launch']);
      } else {
        this.authService.userSubject.next(resp);
      }
    });
    if (this.userData.auth.data.role === 'Super Admin') {
      this.authService.userSubject.subscribe((resp: any) => {
        this.user = resp;
      });
      this.authService.fetch_pharmacy_platformId(this.userData, this.platform).then((resp) => {
        this.pharmacyList = resp.result;
        this.defaultPharmacy = sessionStorage.getItem('pharmacyId');
      })
    } else {
      this.authService.userSubject.subscribe((resp: any) => {
        this.user = resp;
      });
      this.pharmacyList = this.userData.auth.data.pharmacy.filter((data) => {
        return data.deleted === false;
      });
      this.defaultPharmacy = sessionStorage.getItem('pharmacyId');
    }
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url !== '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/us.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Spanish';
      this.selectedLanguageFlag = './assets/img/flags/es.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Portuguese';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    } else if (language === 'de') {
      this.selectedLanguageText = 'German';
      this.selectedLanguageFlag = './assets/img/flags/de.png';
    }
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/pages/launch']);
  }


  // This function is used in open
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  changePharmacy(event: any) {
    if (event.target.value !== 'select') {
      this.authService.passText.next('unselect');
    } else {
      this.authService.passText.next(event.target.value);
    }
    this.authService.productPageRefresh.next(event.target.value);
    if (window.location.pathname === 'dashboard') {
      this.defaultPharmacy = event.target.value === 'select' ? this.pharmacyList[0]._id : event.target.value;
      this.authService.getSelectedPharmacy(event.target.value === 'select' ? this.pharmacyList[0]._id : event.target.value);
      if (sessionStorage.getItem('pharmacyId') !== '') {
        this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(), 'preOrder',
          sessionStorage.getItem('pharmacyId')).then((resp) => {
          this.authService.upcomingOrers.next(resp.orderCount);
        });
      }
    } else {
      this.authService.getSelectedPharmacy(event.target.value === 'select' ? this.pharmacyList[0]._id : event.target.value);
      this.defaultPharmacy = sessionStorage.getItem('pharmacyId');
      if (sessionStorage.getItem('pharmacyId') !== '') {
        this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(), 'preOrder',
          sessionStorage.getItem('pharmacyId')).then((resp) => {
          this.authService.upcomingOrers.next(resp.orderCount);
        });
        this.router.navigate([location.pathname]);
      }
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-pharmacy',
  templateUrl: './list-pharmacy.component.html',
  styleUrls: ['./list-pharmacy.component.scss']
})
export class ListPharmacyComponent implements OnInit {

  modelRadio = 1;
  closeResult = '';
  message = '';
  collapse = true;
  arr: any[] = [
    {selected: false, title: 'Delivery', value: 'delivery'},
    {selected: false, title: 'Pick-Up', value: 'pickup'},
    {selected: false, title: 'Both', value: 'both'},
  ];

  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];


  pharmacy = new FormGroup({
    pharmacy_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    latitude: new FormControl('', [Validators.required, Validators.pattern('[0-9.-]*')]),
    longitude: new FormControl('', [Validators.required, Validators.pattern('[0-9.-]*')]),
    freightAPIKey: new FormControl(''),
    freightAPIURL: new FormControl(''),
    freightAPIPassword: new FormControl(''),
    freightAPIAccount: new FormControl(''),
    freightAPIMasterAccount: new FormControl(''),
    paymentCarrier: new FormControl(''),
    freightCarrier: new FormControl(''),
    freightCharge: new FormControl('', [Validators.pattern('[0-9.]*')]),
  });
  submitted = true;
  userData: any;
  phamacyList: any[] = [];
  selectedPharmacy: any;
  platform: any;
  disbleBtn = false;
  page1 = 1;
  paymentType = false;
  backOrders = false;
  freightUsingAPI = false;
  rows: any[] = [];
  nextPage: any[] = [];
  deliverType = ''
  oldDelType = '';
  showpwd = false;
  flag = 0;
  showConfirmPassword = false;

  constructor(private authService: AuthService, private modalService: NgbModal,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.userData = JSON.parse(sessionStorage.getItem('script-capture'));
    this.loadPharmacies();
  }


  loadPharmacies() {
    this.authService.fetchAllPharmacy(this.userData, 1).then((resp: any) => {
      this.phamacyList = resp;
      let tempPage = 1;
      this.authService.fetchAllPharmacy(this.userData, tempPage + 1).then((nextresonse: any) => {
        if (nextresonse.status === 400) {
          this.nextPage = [];
        } else {
          this.nextPage = nextresonse;
        }
      });
    });
  }

  single_select(ele: any) {
    this.message = ele.title;
    this.deliverType = ele.value;
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  filterupdate(event: any) {
    if (event.key === 'Backspace') {
      this.flag = this.flag + 1;
      if (this.flag === 1) {
        this.loadPharmacies();
      }
    } else {
      this.flag = 0;
      const val = event.target.value.toLowerCase();
      const res = this.phamacyList.filter((d) => {
        return d.pharmacy_name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.phamacyList = res;
    }
  }

  open(content) {
    this.showpwd = false;
    this.showConfirmPassword = false;
    this.collapse = true;
    this.backOrders = false;
    this.disbleBtn = false;
    this.message = '';
    this.submitted = false;
    this.deliverType = ''
    this.paymentType = false;
    this.pharmacy.reset()
    this.modalService.open(content, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEditModal(content, pharmacy) {
    this.submitted = true;
    this.collapse = true;
    this.selectedPharmacy = pharmacy;
    this.oldDelType = this.selectedPharmacy.deliverType;
    this.message = this.selectedPharmacy.deliveryType;
    this.backOrders = this.selectedPharmacy.acceptBackOrders;
    this.deliverType = this.selectedPharmacy.deliverType;
    this.pharmacy.controls['pharmacy_name'].setValue(this.selectedPharmacy.pharmacy_name);
    this.pharmacy.controls['number'].setValue(this.selectedPharmacy.phoneNumber.split('+61')[1] || this.selectedPharmacy.phoneNumber.split('+61')[1]);
    this.pharmacy.controls['email'].setValue(this.selectedPharmacy.email);
    this.pharmacy.controls['address'].setValue(this.selectedPharmacy.address);
    this.pharmacy.controls['suburb'].setValue(this.selectedPharmacy.suburb);
    this.pharmacy.controls['state'].setValue(this.selectedPharmacy.state);
    this.pharmacy.controls['postcode'].setValue(this.selectedPharmacy.postcode);
    this.pharmacy.controls['longitude'].setValue(this.selectedPharmacy.location.coordinates[0]);
    this.pharmacy.controls['latitude'].setValue(this.selectedPharmacy.location.coordinates[1]);

    this.pharmacy.controls['freightAPIKey'].setValue(this.selectedPharmacy.freightAPIKey);
    this.pharmacy.controls['freightCharge'].setValue(this.selectedPharmacy.freightCharge);
    this.pharmacy.controls['freightCarrier'].setValue(this.selectedPharmacy.freightCarrier);
    this.pharmacy.controls['freightAPIPassword'].setValue(this.selectedPharmacy.freightAPIPassword);
    this.pharmacy.controls['paymentCarrier'].setValue(this.selectedPharmacy.paymentCarrier);
    this.pharmacy.controls['freightAPIAccount'].setValue(this.selectedPharmacy.freightAPIAccount);
    this.pharmacy.controls['freightAPIMasterAccount'].setValue(this.selectedPharmacy.freightAPIMasterAccount);
    this.pharmacy.controls['freightAPIURL'].setValue(this.selectedPharmacy.freightAPIURL);
    this.paymentType = this.selectedPharmacy.manualPaymentType || false;
    this.freightUsingAPI = this.selectedPharmacy.freightUsingAPI || false;

    this.modalService.open(content, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }


  openView(view, pharmacy) {
    this.selectedPharmacy = pharmacy;
    this.modalService.open(view, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createPharmacy(modal, backOrders, password, confirmPassword) {
    this.submitted = true;
    if (this.pharmacy.invalid) {
      return;
    }
    if (password.value !== confirmPassword.value) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.deliverType !== 'pickup') {
      if (Number(this.pharmacy.controls['freightCharge'].value) < 0 || this.pharmacy.controls['freightCharge'].value === '' || this.pharmacy.controls['freightCharge'].value === null) {
        this.toastr.error('Incorrect delivery charge amount', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    }
    const body = {
      'pharmacy_name': this.pharmacy.value.pharmacy_name,
      'address': this.pharmacy.value.address,
      'suburb': this.pharmacy.value.suburb,
      'state': this.pharmacy.value.state,
      'postcode': this.pharmacy.value.postcode,
      'email': this.pharmacy.value.email,
      'deliveryType': this.deliverType,
      'phoneNumber': '+61' + parseInt(this.pharmacy.value.number, 10),
      'platformId': this.platform._id,
      'longitude': parseFloat(this.pharmacy.value.longitude),
      'latitude': parseFloat(this.pharmacy.value.latitude),
      'manualPaymentType': this.paymentType,
      freightAPIKey: this.pharmacy.value.freightAPIKey,
      freightAPIPassword: this.pharmacy.value.freightAPIPassword,
      paymentCarrier: this.pharmacy.value.paymentCarrier,
      freightCarrier: this.pharmacy.value.freightCarrier,
      freightAPIAccount: this.pharmacy.value.freightAPIAccount,
      freightAPIMasterAccount: this.pharmacy.value.freightAPIMasterAccount,
      freightAPIURL: this.pharmacy.value.freightAPIURL,
      freightUsingAPI: this.freightUsingAPI,
      adminName: this.pharmacy.value.pharmacy_name,
      password: password.value,
      'acceptBackOrders': !!backOrders.checked,
      freightCharge: String(this.pharmacy.value.freightCharge) === 'null' ? 0 : Number(this.pharmacy.value.freightCharge),
      'businessHours': [
        {
          'timings': {
            'Monday': [
              {
                'startTime': '9:00am',
                'endTime': '9:00pm',
                'status': false
              }
            ],
            'Tuesday': [],
            'Wednesday': [],
            'Thursday': [],
            'Friday': [],
            'Saturday': [],
            'Sunday': []
          }
        }
      ]
    }
    this.disbleBtn = true;
    this.authService.createPharmacy(this.userData, body).then((resp: any) => {

      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Failed', {
          positionClass: 'toast-top-center'
        });
        this.disbleBtn = false;
        modal.dismiss('Cross-Click');
      } else if (resp.status === 500) {
        this.toastr.error('Pharmacy creation failed.', 'Failed', {
          positionClass: 'toast-top-center'
        });
        this.disbleBtn = false;
        modal.dismiss('Cross-Click');
      } else {
        this.toastr.success('Pharmacy created successfully.', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.message = '';
        this.paymentType = false;
        this.currenPage();
        this.disbleBtn = false;
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      if (err.status === 400) {
        this.toastr.error(err.error.message, 'Failed', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.error('Pharmacy creation failed.', 'Failed', {
          positionClass: 'toast-top-center'
        });
      }
      this.disbleBtn = false;
      modal.dismiss('Cross-Click');
    });
  }

  editPharmacy(toogle_backOrdes) {
    this.submitted = true;
    if (this.pharmacy.invalid) {
      return;
    }
    if (Number(this.pharmacy.controls['freightCharge'].value) < 0 || this.pharmacy.controls['freightCharge'].value === '') {
      this.toastr.error('Incorrect delivery charge amount', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = [];
    for (let key in this.pharmacy.controls) {
      if (this.pharmacy.controls[key].dirty) {
        if (key === 'latitude' || key === 'longitude') {
          if (key === 'latitude') {
            body.push({propName: 'location.coordinates.1', value: this.pharmacy.controls[key].value});
          } else {
            body.push({propName: 'location.coordinates.0', value: this.pharmacy.controls[key].value});
          }
        } else if (key === 'number') {
          body.push({propName: 'phoneNumber', value: '+61' + parseInt(this.pharmacy.value.number, 10)});
        } else if (key === 'freightCharge' && this.pharmacy.controls['freightCharge'].dirty) {
          body.push({propName: 'freightCharge', value: String(this.pharmacy.controls['freightCharge'].value)});
        } else {
          body.push({propName: key, value: this.pharmacy.controls[key].value});
        }
      }
    }
    if (this.oldDelType !== this.deliverType) {
      body.push({propName: 'deliveryType', value: this.deliverType});
    }

    if (toogle_backOrdes.checked !== this.selectedPharmacy.acceptBackOrders) {
      body.push({propName: 'acceptBackOrders', value: toogle_backOrdes.checked ? 'true' : 'false'});
    }

    if (body.length === 0) {
      this.toastr.error('Edit any of the fields', 'Updated', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.authService.editPharmacy(this.userData, body, this.selectedPharmacy).then((resp: any) => {
      this.toastr.success('Pharmacy Updated successfully.', 'Updated', {
        positionClass: 'toast-top-center'
      });
      this.currenPage();
      this.pharmacy.reset();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Pharmacy updation failed.', 'Deleted', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    })
  }

  deletePharmacy() {
    this.authService.deletePharmacy(this.userData, this.selectedPharmacy).then((resp: any) => {
      this.toastr.error('Pharmacy deleted successfully.', 'Deleted', {
        positionClass: 'toast-top-center'
      });
      this.currenPage();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Pharmacy deletion failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    });
  }

  openDeletModal(deleteModal, pharmacy) {
    this.selectedPharmacy = pharmacy;
    this.modalService.open(deleteModal, {size: 'md'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    })
  }


  nextPageData() {
    this.page1++;
    this.authService.fetchAllPharmacy(this.userData, this.page1).then((resp: any) => {
      if (resp.status === 400) {
        this.phamacyList = [];
        this.nextPage = [];
      } else {
        this.phamacyList = resp;
        let tempPage = this.page1;
        this.authService.fetchAllPharmacy(this.userData, tempPage + 1).then((nextresonse: any) => {
          if (nextresonse.status === 400) {
            this.nextPage = [];
          } else {
            this.nextPage = nextresonse;
          }

        });
      }
    }).catch((err) => {
    });
  }

  previous() {
    this.page1--;
    if (this.page1 > 0 && this.page1 !== 0) {
      this.authService.fetchAllPharmacy(this.userData, this.page1).then((resp: any) => {
        this.phamacyList = resp;
        let tempPage = this.page1;
        this.authService.fetchAllPharmacy(this.userData, tempPage + 1).then((nextresonse: any) => {
          if (nextresonse.status === 400) {
            this.nextPage = [];
          } else {
            this.nextPage = nextresonse;
          }

        });
      }).catch((err) => {
      });
    } else {
      this.page1 = 1;
    }
  }


  OnChanePayment_mode(event: any) {
    this.paymentType = event.target.checked;
  }

  Update_Payment_mode(event: any
  ) {
    this.paymentType = event.target.checked;
    const body = [{
      propName: 'manualPaymentType',
      value: String(this.paymentType).toString()
    }];
    this.authService.editPharmacy(this.userData, body, this.selectedPharmacy).then((resp: any) => {
    });
  }


  enable_fright_api(event: any
  ) {
    this.freightUsingAPI = event.target.checked;
  }

  Update_freightUsingAPI_mode(event: any
  ) {
    this.freightUsingAPI = event.target.checked;
    const body = [{
      propName: 'freightUsingAPI',
      value: String(this.freightUsingAPI).toString()
    }];
    this.authService.editPharmacy(this.userData, body, this.selectedPharmacy).then((resp: any) => {
    });
  }


  currenPage() {
    this.authService.fetchAllPharmacy(this.userData, this.page1).then((resp: any) => {
      if (resp.status === 400) {
        this.phamacyList = [];
      } else {
        this.phamacyList = resp;
      }
      let tempPage = 1;
      this.authService.fetchAllPharmacy(this.userData, tempPage + 1).then((nextresonse: any) => {
        if (resp.status === 400) {
          this.nextPage = [];
        } else {
          this.nextPage = nextresonse;
        }
      });

    }).catch((err) => {
    });
  }


}

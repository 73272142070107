import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import {ROUTES} from './vertical-menu-routes.config';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {AuthService} from '../auth/auth.service';
import {PHARMACY_ROUTES} from './vertical-pharmacy-menu-routes.config';
import {LocalStorageService} from '../storage/local-storage.service';

const moment = require('moment')

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  userData: any;
  platform: any;
  count: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef, private storage: LocalStorageService,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.menuItems = PHARMACY_ROUTES;

    if (this.userData.auth.data.role === 'Admin' && this.userData.auth.data.userType === 'staff') {
      const menuData = this.menuItems.filter((item) => {
        return item.title !== 'Staff Management' && item.category !== 'Cust'
          && item.title !== 'Pharmacy Info' && item.title !== 'Pharmacy'
          && (item.title !== 'Platform' && item.title !== 'Reports')
      });
      this.menuItems = menuData;
      this.loadPreOrders();
    } else if (this.userData.auth.data.role === 'Super Admin') {
      this.authService.fetch_pharmacy_platformId(this.userData, this.platform).then(async resp => {
        localStorage.setItem('pharmacyId', resp.result.length > 0 ? resp.result[0]._id : '');
        this.loadPreOrders();
      });
      const menuData = this.menuItems.filter((item) => {
        return item.category === 'Plat' || item.category === 'PlatPhar' || item.category === 'PlatPharCust' || item.category === 'PlatCust' || item.category === 'MastPlat';
      });
      this.menuItems = menuData;
    } else if (this.userData.auth.data.role === 'Admin') {
      this.loadPreOrders();
      const menuData = this.menuItems.filter((item) => {
        return item.category === 'Phar' || item.category === 'PlatPhar' || item.category === 'PlatPharCust' || item.category === 'PharCust';
      });
      this.menuItems = menuData;
    } else if (this.userData.auth.data.role === 'User') {
      const menuData = this.menuItems.filter((item) => {
        return item.category === 'Cust' || item.category === 'PlatCust' || item.category === 'PharCust' || item.category === 'PlatPharCust';
      });
      this.menuItems = menuData;
    } else {
      const menuData = this.menuItems.filter((item) => {
        return item.category === 'Mast' || item.category === 'MastPlat'
      });
      this.menuItems = menuData;
    }

  }


  loadPreOrders() {
    this.authService.upcomingOrers.subscribe((resp: any) => {
      this.menuItems.map((menuItem) => {
        if (menuItem.title === 'Scripts On File') {
          menuItem.badge = String(resp).toString();
        }
      });
    })

    this.authService.productPageRefresh.subscribe((data: string) => {
      localStorage.setItem('pharmacyId', data);
      this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(),
        'new', localStorage.getItem('pharmacyId')).then((resp) => {
        this.count = resp.orderCount || 0;
        this.menuItems.map((menuItem) => {
          if (menuItem.title === 'Orders') {
            menuItem.badge = String(this.count).toString();
          }
        });
      });
      this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(),
        'preOrder', localStorage.getItem('pharmacyId')).then((resp) => {
        this.count = resp.orderCount;
        this.menuItems.map((menuItem) => {
          if (menuItem.title === 'Scripts On File') {
            menuItem.badge = String(this.count).toString();
          }
        });
      });
      return;
    });
    this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(),
      'preOrder', localStorage.getItem('pharmacyId')).then((resp) => {
      this.count = resp.orderCount;
      this.menuItems.map((menuItem) => {
        if (menuItem.title === 'Scripts On File') {
          menuItem.badge = String(this.count).toString();
        }
      });
    });
    this.authService.fetch_orders_data_status(this.userData, moment(new Date()).format(), moment(new Date()).format(),
      'new', localStorage.getItem('pharmacyId')).then((resp) => {
      this.count = resp.orderCount || 0;
      this.menuItems.map((menuItem) => {
        if (menuItem.title === 'Orders') {
          menuItem.badge = String(this.count).toString();
        }
      });
    });
  }


  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      // if (this.userData.auth.data.role === 'Admin') {
      //   this.menuItems = PHARMACY_ROUTES;
      // } else {
      //   this.menuItems = ROUTES;
      // }
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}

<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="{{this.platform.logo}}" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <h4 class="mb-2 card-title">Reset Password</h4>
                <div class="pt-2" *ngIf="userData.email === 'true'">
                  <form [formGroup]="resetPassowordForm" (ngSubmit)="ChangePassword()">
                    <div class="form-group">
                      <div class="input-group">
                        <input type="password" formControlName="new_password" #passsword class="form-control"
                               [ngClass]="{ 'is-invalid':submitted && resetPassowordForm.controls['new_password'].hasError('required') }"
                               placeholder="New Password">
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                            [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <div *ngIf="resetPassowordForm.get('new_password')?.invalid && resetPassowordForm.get('new_password')?.touched">
                        <small class="text-danger">Password is required</small>
                      </div>
                      <div *ngIf="resetPassowordForm.controls['new_password'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number. <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>
                      </div>
                      <div *ngIf="isSequential">
                        <small class="text-danger">Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input type="password" formControlName="confirm_password" #confirmpasssword class="form-control" (change)="mismatch = false"
                               [ngClass]="{ 'is-invalid':submitted && resetPassowordForm.controls['confirm_password'].hasError('required') }"
                               placeholder="Confirm Password">
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer"
                                (click)="showPassword(confirmpasssword,'confirmPassword')">
                            <i [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <div *ngIf="resetPassowordForm.get('confirm_password')?.invalid && resetPassowordForm.get('confirm_password')?.touched">
                        <small class="text-danger">Confirm password is required</small>
                      </div>
                      <div
                        *ngIf="mismatch">
                        <small class="text-danger">Password is mismatch</small>
                      </div>
                      <div  *ngIf="resetPassowordForm.controls['confirm_password'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number. <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>

                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button type="submit" class="btn secondary-btn">Change Password</button>
                    </div>
                  </form>
                </div>
                <form [formGroup]="forgotpassWrdForm" (ngSubmit)="onSubmit()" *ngIf="userData.email === 'false'">
                  <div class="form-group">
                    <input type="text" maxlength="10" formControlName="number" class="form-control"
                           placeholder="Phone number"
                           [ngClass]="{ 'is-invalid':loginFormSubmitted && forgotpassWrdForm.controls['number'].hasError('required') }"
                           required>
                    <div *ngIf="loginFormSubmitted && forgotpassWrdForm.controls['number'].hasError('required')"
                         class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> Phone number
                      is
                      required
                    </div>
                    <div *ngIf="forgotpassWrdForm.controls['number'].hasError('pattern')"
                         class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> Invalid phone
                      number
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <a [routerLink]="['../login']" class="btn secondary-btn"><i class="ft-chevron-left mr-1"></i>Back</a>
                      <button type="submit" class="btn primary-btn float-right">Submit</button>
                    </div>
                  </div>
                </form>
                <div class="pt-2" *ngIf="visible">
                  <form [formGroup]="OtpForm" (ngSubmit)="onSubmitOTP()">
                    <div class="form-group">
                      <input type="text" maxlength="4" formControlName="otp" class="form-control" placeholder="OTP"
                             [ngClass]="{ 'is-invalid':OtpSubmit && OtpForm.controls['otp'].hasError('required') }"
                             required>
                      <div *ngIf="OtpSubmit && OtpForm.controls['otp'].hasError('required')"
                           class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> OTP is
                        required
                      </div>
                      <div *ngIf="OtpForm.controls['otp'].hasError('pattern')"
                           class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> Enter 4
                        digit OTP
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input type="password" formControlName="new_password" #passsword class="form-control"
                               placeholder="Password" (keyup.enter)="onSubmit()"
                               [ngClass]="{ 'is-invalid':OtpSubmit && OtpForm.controls['new_password'].hasError('required') }"
                               required>
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                            [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <div *ngIf="OtpForm.get('new_password')?.invalid && OtpForm.get('new_password')?.touched">
                        <small class="text-danger">Password is required</small>
                      </div>
                      <div *ngIf="OtpForm.controls['new_password'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number. <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>
                      </div>
                      <div *ngIf="OtpSubmit && OtpForm.controls['new_password'].hasError('required')"
                           class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i>Password
                        required
                      </div>
                      <div *ngIf="isSequential">
                        <small class="text-danger">Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input type="password" formControlName="confirm_password" #confirmpasssword class="form-control" (change)="mismatch = false"
                               placeholder="Confirm Password" (keyup.enter)="onSubmit()"
                               [ngClass]="{ 'is-invalid':OtpSubmit && OtpForm.controls['confirm_password'].hasError('required') }"
                               required>
                        <div class="input-group-prepend">
                                          <span class="input-group-text cursor-pointer"
                                                (click)="showPassword(confirmpasssword,'confirmPassword')"><i
                                            [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <div *ngIf="OtpForm.get('confirm_password')?.invalid && OtpForm.get('confirm_password')?.touched">
                        <small class="text-danger">Password is required</small>
                      </div>
                      <div
                        *ngIf="mismatch">
                        <small class="text-danger">Password is mismatch</small>
                      </div>
                      <div *ngIf="OtpForm.controls['confirm_password'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number. <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>
                      </div>
                      <div *ngIf="OtpSubmit && OtpForm.controls['confirm_password'].hasError('required')"
                           class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i>Password
                        required
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button type="submit" class="btn secondary-btn">Change Password</button>
                    </div>
                  </form>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->

<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="{{this.platform.logo}}" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3" *ngIf="!enable2Fa">
                <h4 class="mb-2 card-title">Login</h4>
                <p>Welcome please login to your account.</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <form [formGroup]="loginForm">
                  <div class="form-group">
                    <input type="text" formControlName="username" class="form-control" placeholder="Username"
                           [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                           required>
                    <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                         class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                  </div>
                  <div class="form-group">

                    <div class="input-group">
                      <input type="password" formControlName="password" #passsword class="form-control"
                             placeholder="Password" (keyup.enter)="onSubmit()"
                             [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                             required>
                      <div class="input-group-prepend">
                        <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                          [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                      </div>
                    </div>


                    <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                         class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                      required
                    </div>
                  </div>
                  <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                    <div class="remember-me mb-2 mb-sm-0">
                      <div class="checkbox auth-checkbox primary-checkbox">
                        <input type="checkbox" (change)="rememberMe($event)"
                               [checked]="checked"
                               class="form-control primary-input" id="rememberMe">
                        <label for="rememberMe" class="primary-checkbox"><span
                          class="font-small-2 mb-3 font-weight-normal">Remember Me</span></label>
                      </div>
                    </div>
                    <a [routerLink]="['../forgot-password']" routerLinkActive="active">Forgot Password?</a>
                  </div>
                  <div class="d-flex justify-content-center flex-sm-row flex-column">
                    <a class="btn secondary-btn" (click)="onSubmit()">Login</a>
                  </div>
                </form>
                <hr>
                <div class="d-flex justify-content-center align-items-center">
                  <ngx-spinner></ngx-spinner>
                </div>
              </div>
              <div class="col-lg-6 col-12 px-4 py-3" *ngIf="enable2Fa">
                <h4 class="mb-2 card-title">2 Factor Authentication</h4>
                <p>To keep your account safe we want to make sure its really you</p>
                <p class="text-success">{{message}}</p>

                <form [formGroup]="Twofact_form" (ngSubmit)="authenticate_twofa()">
                  <div class="form-group">
                    <input type="text" formControlName="otp" class="form-control" placeholder="Enter 6 digit OTP" maxlength="6">
                    <div *ngIf="Twofact_form.controls['otp'].invalid && submited">
                      <small class="text-danger">OTP is required</small>
                    </div>
                  </div>
                  <div>
                    <a class="btn primary-btn" (click)="reset()"><i class="ft-chevron-left"></i>Back </a>
                    <button class="btn secondary-btn float-right" type="submit">Submit<i class="ft-chevron-right"></i></button>
                  </div>
                  <div class="text-center pt-1">
                    <p>Didn't get a code <a (click)="getAuthCode()" style="text-decoration: underline !important;">Send Again</a></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->

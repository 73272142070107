<div class="container">
  <div class="row">
    <div class="col-xs-12 col-lg-12 text-center p-1">
      <h5 class="pt-3">Electronic  Prescription for {{this.name}}</h5>
    </div>
    <div class="col-xs-12 col-lg-12 text-center p-1">
      <qrcode [qrdata]="this.ScriptRows?.token" [width]="60"
              [errorCorrectionLevel]="'M'"></qrcode>
      {{ this.ScriptRows?.token }}
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-xs-12 col-lg-12 text-center">
      <h3 *ngFor="let prodName of this.productNames">{{prodName}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-lg-12 text-center">
      <div style="padding:20px" *ngIf="ScriptRows.status === 'dispensed'"><h3><b>This prescription has already been dispensed.</b></h3></div>
      <div style="padding:20px" *ngIf="ScriptRows.status === 'active'"><h3><b>This prescription is Active.</b></h3></div>
      <div style="padding:20px" *ngIf="ScriptRows.status === 'cancelled'"><h3><b>This prescription has been cancelled.</b></h3></div>
      <div style="padding:20px" *ngIf="ScriptRows.status === 'ceased'"><h3><b>This prescription has been ceased.</b></h3></div>
      <div style="padding:20px" *ngIf="ScriptRows.status === 'expired'"><h3><b>This prescription has been expired.</b></h3></div>
      <div style="padding:20px" *ngIf="ScriptRows.status === 'disabled'"><h3><b>This prescription has been disabled.</b></h3></div>
    </div>
  </div>
  <div class="row" style="padding-bottom: 10px">
    <div class="col-xs-12 col-lg-12 text-center" style="align-items:center">
      <img src="assets/img/eRxLogo.png" height="22" />
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-xs-12 col-lg-12" style="text-align:center;line-height:80%">
      <span style="font-size:9px;">Privacy Notice: The privacy and security of your personal information is important to us, and is protected by law.  We need to collect this information so we can process and manage your applications and payments, and provide services to you. <br> We only share your information with other parties where you have agreed, or where the law allows or requires it.  For more information, go to servicesaustralia.gov.au/privacy</span>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-xs-12 col-lg-12 text-center">
    </div>
  </div>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WizardComponent} from 'angular-archwizard';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {


  @ViewChild(WizardComponent)
  wizard: WizardComponent;
  title = '';
  existingPharmacies: any = [];
  active = 1;
  userData: any;
  selectedStaff: any;
  closeResult = '';
  submitted = true;
  staffList: any[] = [];
  staffForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl('',),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirmpwd: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  })
  pharmacyAdmin = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl('',),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirmpwd: new FormControl('', [Validators.required]),
    pharmacy: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  })
  EditAdmin = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(''),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    pharmacy: new FormControl(''),
    number: new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  })

  Editstaff = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl('',),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    number: new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  })
  platform: any;
  phamacyList: any = [];
  selectedPharmacies: any[] = [];
  selectedPharmacy: any;
  pharmacyAdmins: any = [];
  showpwd = false;
  showConfirmPassword = false;

  constructor(private authService: AuthService, private modalService: NgbModal, private storage: LocalStorageService,
              private toastr: ToastrService, private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        sessionStorage.setItem('pharmacyId', data);
        this.loadingScreen();
        this.loadStaffs();
        this.loadPharmacies();
        if (this.userData.auth.data.role === 'Super Admin') {
          this.loadPharmacyAdmins();
        }
      }
      return;
    });
    this.loadStaffs();
    this.loadPharmacies();
    if (this.userData.auth.data.role === 'Super Admin') {
      this.loadPharmacyAdmins();
    }

  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  loadStaffs() {
    this.authService.fetchStaff(this.userData).then((resp: any) => {
      if (resp.response === 'the total user length is Zero') {
        this.staffList = [];
        this.spinner.hide();
      } else {
        this.staffList = resp.body;
        this.spinner.hide();
      }
    }).catch(err => {
      console.log(err);
      this.spinner.hide();
    });
  }

  loadPharmacyAdmins() {
    this.authService.fetch_pharmacy_admin(this.userData, this.platform).then((resp: any) => {
      this.pharmacyAdmins = resp.body.filter((admins) => {
        return admins.userType === 'user';
      });
    }).catch(err => {
      console.log(err);
    });
  }


  loadPharmacies() {
    this.authService.fetchAllPharmacy(this.userData, 1).then((resp: any) => {
      this.phamacyList = resp;
      this.selectedPharmacy = this.phamacyList[0]
    });
  }


  open(content, size = 'xl') {
    this.showConfirmPassword = false;
    this.showpwd = false;
    this.submitted = false;
    this.staffForm.reset();
    this.modalService.open(content, {size: size, backdrop: 'static'});
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createStaff(checkBox: HTMLInputElement) {
    this.submitted = true;
    if (this.staffForm.invalid) {
      return;
    }

    if (this.staffForm.controls['password'].value !== this.staffForm.controls['confirmpwd'].value) {
      this.toastr.error('Password mismatch.', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    let TACAccepted = false;
    let PSAccepted = false;
    if (!checkBox.checked) {
      this.toastr.error('Please accept the terms and conditions', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      TACAccepted = true;
      PSAccepted = true;
    }


    const body = {
      firstName: this.staffForm.value.firstName,
      middleName: this.staffForm.value.middleName === null ? ' ' : this.staffForm.value.middleName,
      lastName: this.staffForm.value.lastName,
      email: this.staffForm.value.email,
      password: this.staffForm.value.password,
      number: '+61' + parseInt(this.staffForm.value.number, 10),
      role: 'Admin',
      userType: 'staff',
      PSAccepted: PSAccepted,
      TACAccepted: TACAccepted,
      twofaEnabled: true,
      consentAccepted: true,
      platformId: this.platform._id,
      pharmacyId: sessionStorage.getItem('pharmacyId')
    }
    this.authService.createStaff(this.userData, body).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error('User already exists.', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Staff created successfully.', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadStaffs();
        this.modalService.dismissAll();
      }
    }).catch(err => {
      this.toastr.error('Staff creation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  createPharmacyAdmin() {
    this.submitted = true;
    if (this.pharmacyAdmin.invalid) {
      return;
    }

    if (this.pharmacyAdmin.controls['password'].value !== this.pharmacyAdmin.controls['confirmpwd'].value) {
      this.toastr.error('Password mismatch.', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    const pharmacies = [];
    this.selectedPharmacies.forEach(pharmacy => {
      pharmacies.push(pharmacy._id);
    })

    const body = {
      firstName: this.pharmacyAdmin.value.firstName,
      middleName: this.pharmacyAdmin.value.middleName,
      lastName: this.pharmacyAdmin.value.lastName,
      email: this.pharmacyAdmin.value.email,
      password: this.pharmacyAdmin.value.password,
      number: '+61' + parseInt(this.pharmacyAdmin.value.number, 10),
      role: 'Admin',
      userType: 'user',
      twofaEnabled: true,
      pharmacy: pharmacies,
      platformId: this.platform._id,
    }
    this.authService.register_new_user(body).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.success('Pharmacy Admin created successfully.', 'Success', {
        positionClass: 'toast-top-center'
      });
      this.loadPharmacyAdmins();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Pharmacy Admin creation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  deleteStaff() {
    this.authService.deleteStaff(this.userData, this.selectedStaff).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll();
      } else {
        if (this.selectedStaff.userType === 'user') {
          this.toastr.error('Pharmacy Admin deleted successfully.', 'Deleted', {
            positionClass: 'toast-top-center'
          });
          this.loadPharmacyAdmins();
        } else {
          this.toastr.error('Staff deleted successfully.', 'Deleted', {
            positionClass: 'toast-top-center'
          });
          this.loadStaffs();
        }
        this.modalService.dismissAll();
      }

    }).catch(err => {
      this.toastr.error('Staff deletion failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    });
  }

  openDeleteModal(deleteModal, staff) {
    this.selectedStaff = staff;
    if (this.selectedStaff.userType === 'user') {
      this.title = 'Would you like to delete this Pharmacy Admin?';
    } else {
      this.title = 'Would you like to delete this Staff?';
    }

    this.modalService.open(deleteModal, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openEditModal(editmodal, staff) {
    this.existingPharmacies = [];
    this.submitted = true;
    this.selectedStaff = staff;

    if (staff.userType === 'user') {
      const pharmacies = this.selectedStaff.pharmacy;
      this.EditAdmin.controls['pharmacy'].setValue(pharmacies);
      this.EditAdmin.controls['firstName'].setValue(this.selectedStaff.firstName);
      this.EditAdmin.controls['middleName'].setValue(this.selectedStaff.middleName);
      this.EditAdmin.controls['lastName'].setValue(this.selectedStaff.lastName);
      this.EditAdmin.controls['email'].setValue(this.selectedStaff.email);
      this.EditAdmin.controls['number'].setValue(this.selectedStaff.number.split('+61')[1]);
    } else {
      this.Editstaff.controls['firstName'].setValue(this.selectedStaff.firstName);
      this.Editstaff.controls['middleName'].setValue(this.selectedStaff.middleName);
      this.Editstaff.controls['lastName'].setValue(this.selectedStaff.lastName);
      this.Editstaff.controls['email'].setValue(this.selectedStaff.email);
      this.Editstaff.controls['number'].setValue(this.selectedStaff.number.split('+61')[1]);
    }

    this.modalService.open(editmodal, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });


  }


  editStaff() {
    this.submitted = true;
    if (this.Editstaff.invalid) {
      return;
    }
    let body = [];
    for (let key in this.Editstaff.controls) {
      if (key === 'number' && this.Editstaff.controls[key].dirty) {
        body.push({propName: key, value: '+61' + parseInt(this.Editstaff.value.number, 10)});
      } else if (this.Editstaff.controls[key].dirty) {
        body.push({propName: key, value: this.Editstaff.controls[key].value});
      }
    }
    if (body.length === 0) {
      this.toastr.error('Please edit any of the fields.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
      return;
    }

    this.authService.editstaff(this.userData, body, this.selectedStaff).then((resp: any) => {
      this.toastr.info('Staff details updated successfully', 'Info', {
        positionClass: 'toast-top-center'
      });
      this.loadStaffs();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Staff updation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    });

  }


  EditpushPharmacy(event) {
    this.selectedPharmacies = event;
  }

  updatePharmacyAdmin(modal) {
    this.submitted = true;
    if (this.EditAdmin.invalid) {
      return;
    }
    let body = [];
    for (let key in this.EditAdmin.controls) {
      if (key === 'number' && this.EditAdmin.controls[key].dirty) {
        body.push({propName: key, value: '+61' + parseInt(this.EditAdmin.value.number, 10)});
      } else if (key === 'pharmacy' && this.EditAdmin.controls[key].dirty) {
        const pharmacies = [];
        this.selectedPharmacies.forEach(pharmacy => {
          pharmacies.push(pharmacy._id);
        })
        body.push({propName: key, value: pharmacies})
      } else if (this.EditAdmin.controls[key].dirty) {
        body.push({propName: key, value: this.EditAdmin.controls[key].value});
      }
    }

    if (body.length === 0) {
      this.toastr.error('Please edit any of the fields.', 'Error', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-click');
      this.EditAdmin.reset()
      return;
    }

    this.authService.editstaff(this.userData, body, this.selectedStaff).then((resp: any) => {
      this.toastr.info('Pharmacy Admin updated successfully.', 'Info', {
        positionClass: 'toast-top-center'
      });
      this.loadPharmacyAdmins();
      modal.dismiss('Cross-click');
    }).catch(err => {
      this.toastr.error('Pharmacy Admin updation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    });
  }


  resetClose(modal: any) {
    this.EditAdmin.reset();
    modal.dismiss('Cross-Click');
  }

  filterUpDate(event: any) {

    if (event.key === 'Backspace') {
      this.loadStaffs();
    } else {
      let val = event.target.value.toLowerCase();
      const res = this.staffList.filter((staff: any) => {
        return staff.firstName.toLowerCase().indexOf(val) !== -1 || !val;
      });
      if (res.length) {
        this.staffList = res;
      }
    }

  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }
}

import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectorRef, ViewChildren, HostListener
} from '@angular/core';
import {QRCodeModule} from 'angularx-qrcode';
import {ModalDismissReasons, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';

const moment = require('moment');
import {AuthService} from '../../../../shared/auth/auth.service';
import {DatatableComponent, ColumnMode} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Clipboard} from '@angular/cdk/clipboard';
import {Json2csvService} from '../../../../shared/services/json2csv.service';
import {WizardComponent} from 'angular-archwizard';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {JsonXlsxService} from '../../../../shared/services/json-xlsx.service';
import {HttpClient} from '@angular/common/http';
import {Order} from './order';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const now = new Date();

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {

  @ViewChild('marketingTitle') titleInputRef: ElementRef;
  @ViewChild('marketingMessage') messageInputRef: ElementRef;
  @ViewChild('viewOrder') viewOrderModal: ElementRef;
  @ViewChildren(WizardComponent) wizard: WizardComponent;
  BAG = 'task-group';
  userData: any;
  dateOfBirth: any;
  tempOrders = [];
  shippedDate = '';
  model: NgbDateStruct | null;
  type = 'name';
  public ColumnMode = ColumnMode;
  addReson = ''
  submitted = false;
  hiddeShipBtn = false;
  closeResult: string;
  selectedMedicine_Index: number;
  scriptImage_for = 'for_complete_order';
  selectedReason: string;
  newOrders: any[] = [];
  downloadAllCheck = false;
  packing: any[] = [];
  shipping: any[] = [];
  scriptImage = '';
  delivered: any[] = [];
  pickedUp: any[] = [];
  selectedOrder: Order | any;
  active = 1;
  choiceMade = '';
  showTokenForm = false;
  ItemsTotal = 0;
  showMr = false;
  rows: any[] = [];
  Medicinerows: any[] = [];
  customerList: any[] = [];
  selectedCustomer: any;
  modal: any;
  isCollapsed = true;
  showMessageOnce = 0;
  page1 = 1;
  sendToShipp: any = [];
  confirmationMessage = '';
  globalOrderStatus = 'new';
  prev = 'new';
  changeColor = false;
  showUrl = false;
  pharmacyUser: any;
  licenseFront: any;
  licenseBack: any;
  selectedItem: any;
  customerNotes = [];
  pharmacyNotes = [];
  expand = false;
  productList: any[] = [];
  reasons: any[] = ['Item out of stock', 'Others']
  index: number;
  shippedOrders: any[] = [];
  downloadOrders: any[] = [];
  editProd = new FormGroup({
    product_name: new FormControl('', Validators.required),
    price: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*')]),
    note: new FormControl(''),
    instruction: new FormControl(''),
  });
  selectedCustomer_Address: any;
  scriptForm = new FormGroup({
    token: new FormControl('', [Validators.required, Validators.pattern('[A-Z0-9]{18}')])
  })
  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(' '),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });

  customerSelected: any;
  tempRecord = {
    'orderId': '',
    'orderedItem': []
  }

  tempRecordArray: any = [];
  selectedPharmacy: any;
  childOrder: any;
  btnTitle = 'Verify';
  orderedItems: any[] = [];
  partialShippments = [];
  orderedDates: any[] = [];
  modalName = '';
  selectCustomerFlag: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  selectedProduct: any;
  platform: any;
  pharmacy: any;
  today = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  }
  editedOrder = false;
  public limitRef = 10;
  public columns = [
    {name: 'Name', prop: 'name'},
    {name: 'Phone number', prop: 'number'},
  ];
  enableReadOnly = true;
  modalType = '';
  receiptAcc = new FormGroup({
    orderPayment_method: new FormControl('', [Validators.required]),
    orderAmount: new FormControl('', [Validators.required, Validators.pattern('^[0-9.]+$')]),
    orderPayment_reference: new FormControl('', [Validators.required,
      Validators.pattern('[a-zA-Z0-9]*')]),
  })
  isPartialShipment = false;
  carrierForm = new FormGroup({
    carrier_number: new FormControl('', [Validators.required]),
    connate_num: new FormControl('', [Validators.required]),
    trackUrl: new FormControl(''),
  })
  orderBy: any;
  searchBy = [
    {title: 'Name', value: 'customerName', type: 'filter'},
    {title: 'Phone Number', value: 'customerNumber', type: 'filter'},
    {title: 'Email', value: 'customerEmail', type: 'filter'},
    {title: 'Order No', value: 'orderNumber', type: 'filter'}
  ];
  search = {title: 'Name', value: '', type: 'order'};
  searchPhone = new FormGroup({
    phone: new FormControl('', [Validators.pattern('[0-9]*'), Validators.required]),
    email: new FormControl('', [Validators.pattern('[0-9a-zA-Z]')]),
    name: new FormControl('', [Validators.pattern('^(?!\\s).*$')])
  })
  private selectedMedicince: any;
  private selectedIndex: any;
  private selectedScript: any;
  check = false;
  frontLicense: string;
  backLicense: string;
  selectedStep = '';
  userStatus: any;
  pickup = false;
  myorderObj: any
  deliverTypeSelected = false;
  lockButton = false;
  target = '';
  delivery_Instruction: string;
  deliverModal = {
    delivery: false,
    pickup: false
  }
  quantity = 0;
  defaultAddess: any;
  orderedItemsModal = {
    'token': '',
    'product_brand_name': '',
    'product_name': '',
    'medication_strength': '',
    'quantity': '',
    'price': 0,
    'repeats': 0,
    'instruction': '',
    'note': '',
    'globalProductId': ''

  }
  showAddressForm = false;
  userCreated: any;
  btns = true;
  sample = {
    'fallowUp_note': '',
    'fallowUp_timeStamp': '',
    '_id': ''
  }
  createdDivs: any[] = [];
  Token_updation_body = [];
  token_updation = {
    'repeats': '',
    'duration': '',
    'token': '',
    'scriptData': {
      'uploadedScript': '', 'type': ''
    }
  }
  disablebtn = false;
  paymentTrial = 'firstTime';

  rowSelected = {
    'selected': ''
  }
  enable = true;

  dob: string;
  medicareLicense = '';
  pensionCardNumber = ''
  showAuthTPAForm = false;
  showSubmitbtn = false;
  validation = false;
  deliverCharge = 0;
  validate_repeat = false;
  uploadType = '';
  showpwd = false;
  showConfirmPassword = false;
  message = '';
  maxPageSize = 0;
  nextpageLength = [];
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  selectedState = null;
  searchValue = '';
  viewScript = '';
  issuesCatgory = [];
  selectedCategory = 'Filter';
  showForm = false;
  isOrderData = 'noOrderData';
  SelectedIssueCategory = '';
  categorySelected: any;
  partialOrderObj = {}
  checkboxStatus = false;
  private debounceTimer: any;

  constructor(private elRef: ElementRef,
              private clipboard: Clipboard, private monthpipe: MonthFormatPipe,
              private router: Router, private jsonToXlsxService: JsonXlsxService,
              private authService: AuthService, private http: HttpClient,
              private jsonCSv: Json2csvService, private spinner: NgxSpinnerService,
              private toastr: ToastrService, private modalService: NgbModal,
              private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private ref: ChangeDetectorRef, private qrCode: QRCodeModule) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        localStorage.setItem('pharmacyId', data);
        this.globalOrderStatus = 'new';
        this.loadingScreen();
        this.loadOrders();
        this.fetchOrderIssues_category();
        this.authService.fetch_particular_pharmacy(this.userData).then(async (resp) => {
          this.pharmacy = resp;
          this.selectedPharmacy = resp;
          this.loadProducts()
        });
      }
      return;
    });
    this.loadOrders();
    this.fetchOrderIssues_category();
    this.authService.fetch_particular_pharmacy(this.userData).then(async (resp) => {
      this.pharmacy = resp;
      this.selectedPharmacy = resp;
      this.loadProducts()
    });
  }

  fetchOrderIssues_category() {
    this.authService.fetchOrderIssues_category(this.userData, this.platform).then((response: any) => {
      if (response.status === 500) {
        this.issuesCatgory = [];
        return;
      } else {
        this.issuesCatgory = response.listOfCategory;
      }
    });
  }

  loadOrders() {
    this.authService.fetchOrders(this.userData, 'new', this.page1, sessionStorage.getItem('pharmacyId')).then((resp: any) => {
      this.rows = resp.results.filter((data) => {
        return !data.issueReported;
      });
      this.maxPageSize = resp.totalPages;
      this.downloadOrders = this.rows;
      this.spinner.hide();
      if (this.rows.length === 0) {
        this.message = 'There are currently no new orders to process';
        this.spinner.hide();
      }
      this.rows.sort((a, b) => {
        const dateA = moment(a.orderDate);
        const dateB = moment(b.orderDate);

        if (dateA.isBefore(dateB)) {
          return 1;
        } else if (dateA.isAfter(dateB)) {
          return -1;
        } else {
          return 0;
        }
      });

    });
  }


  clear(event: any) {
    if (event.key === 'Backspace') {
      this.currentPage();
    }
  }


  searchOrder(value) {
    if (value.trim() === '') {
      this.toastr.error('Enter ' + this.search.title, 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.globalOrderStatus = !this.checkboxStatus && this.globalOrderStatus === '' ? 'new' : !this.checkboxStatus && this.globalOrderStatus !== '' ? this.globalOrderStatus : '';
    if (this.search.title === 'Phone Number') {
      this.searchValue = value.trim();
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus).then((response: any) => {
        if (this.globalOrderStatus === 'issues') {
          this.rows = response.results;
        } else {
          if (this.checkboxStatus) {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          } else {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          }
        }
        this.maxPageSize = response.totalPages;
        this.downloadOrders = this.rows;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    } else if (this.search.title === 'Order No') {
      const pattern = /\d/;
      if (!pattern.test(value)) {
        this.toastr.error('Invalid ' + this.search.title, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.searchValue = value.trim();
        this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus).then((response: any) => {
          if (this.globalOrderStatus === 'issues') {
            this.rows = response.results;
          } else {
            if (this.checkboxStatus) {
              this.rows = response.results.filter(data => data.status !== 'preOrder');
            } else {
              this.rows = response.results.filter(data => data.status !== 'preOrder');
            }
          }
          this.downloadOrders = this.rows;
          this.maxPageSize = response.totalPages;
          if (this.rows.length === 0) {
            this.toastr.error('Orders not found', 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
        })
      }
    } else if (this.search.title === 'Email') {
      this.searchValue = value.trim();
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus).then((response: any) => {
        if (this.globalOrderStatus === 'issues') {
          this.rows = response.results;
        } else {
          if (this.checkboxStatus) {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          } else {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          }
        }
        this.downloadOrders = this.rows;
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      })
    } else {
      this.searchValue = value.trim();
      this.authService.searchOrder(this.userData, this.selectedPharmacy, 'customerName', this.searchValue, 1, this.globalOrderStatus).then((response: any) => {
        if (this.globalOrderStatus === 'issues') {
          this.rows = response.results;
        } else {
          if (this.checkboxStatus) {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          } else {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          }
        }
        this.downloadOrders = this.rows;
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }
    this.globalOrderStatus = this.prev;
  }


  loadProducts() {
    const pharmacyId = localStorage.getItem('pharmacyId');
    this.authService.loadProductsList_of_pharmacy(this.userData, pharmacyId, this.platform).then((resp: any) => {
      if (this.selectedPharmacy.acceptBackOrders) {
        this.productList = resp.results;
      } else {
        // this.productList = resp.results.filter(prod => prod.stock > 0);
        this.productList = resp.results;
      }
    }).catch(err => {
    });
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, this.selectedPharmacy, 'orderNumber', orderNo, 1, '').then(async (response: any) => {
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'max', backdrop: 'static'});
    });
  }


  open(content, item, index) {
    this.index = index
    this.selectedItem = item;
    this.editProd.controls['product_name'].setValue(this.selectedItem.product_name)
    this.editProd.controls['price'].setValue(this.selectedItem.price);
    this.editProd.controls['note'].setValue(this.selectedItem.note);
    this.editProd.controls['instruction'].setValue(this.selectedItem.instruction);
    this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  openConfimation(confirm, item, step = 'step-2') {
    if (step === 'step-2') {
      this.selectedMedicince = item;
    } else {
      this.selectedMedicince = {...this.selectedMedicince, ['productName']: item.product_name};
    }
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'});
  }


  openNewOrderModal(pickup, order) {
    this.selectedOrder = order;
    this.orderedItems = order.orderedItem;
    for (const item of order.orderedItem) {
      this.ItemsTotal += item.cost;
    }
    this.modalService.open(pickup, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // This function is used in open
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const activeElement = document.activeElement;
    const isInputField = activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA'
    );

    if (!isInputField && (event.key === 'x' || event.key === 'X')) {
      this.modalService.dismissAll('x-key-press');
    }
  }

  setItem(medicine, event, token, repeats) {
    this.orderedItemsModal.token = token.value.toUpperCase();
    this.orderedItemsModal.price = medicine.productCost;
    this.orderedItemsModal.product_brand_name = medicine.brandName;
    this.orderedItemsModal.product_name = medicine.productName;
    this.orderedItemsModal.quantity = medicine.quantity;
    this.orderedItemsModal.repeats = repeats.value === '' ? 0 : Number(repeats.value);
    this.orderedItemsModal.globalProductId = medicine.globalProductId;
    this.orderedItemsModal.instruction = '';
    this.orderedItemsModal.note = '';

    //
    // if (this.orderedItemsModal.token === '') {
    //   this.check = false;
    //   this.toastr.warning('Please enter the Token Number ', 'Warning', {
    //     positionClass: 'toast-top-center'
    //   });
    //   return;
    // }
    //
    // // @ts-ignore
    // if (this.orderedItemsModal.quantity === 0) {
    //   this.check = false
    //   this.toastr.warning('Please update the quantity ', 'Warning', {
    //     positionClass: 'toast-top-center'
    //   });
    //   return;
    // }


    this.check = event.target.checked;
    if (this.orderedItems.length > 0) {
      const check = this.orderedItems.some((item) => {
        return item.product_name === this.orderedItemsModal.product_name;
      })
      if (check) {
        const filtered = this.orderedItems.filter((item) => {
          return this.orderedItemsModal.product_name !== item.product_name;
        });
        this.orderedItems = filtered;
      } else {
        this.orderedItems.push(this.orderedItemsModal);
      }
    } else {
      this.orderedItems.push(this.orderedItemsModal);
    }
    this.orderedItemsModal = {
      'token': '',
      'product_brand_name': '',
      'product_name': '',
      'medication_strength': '',
      'quantity': '',
      'price': 0,
      'repeats': 0,
      'instruction': '',
      'note': '', 'globalProductId': ''
    };
  }

  assignStatus(status) {
    this.globalOrderStatus = status;
  }

  filter(status) {
    this.page1 = 1;
    this.prev = status;
    this.search.type = 'order';
    if (this.searchValue !== '') {
      this.globalOrderStatus = status;
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page1, this.globalOrderStatus).then((response: any) => {
        if (this.searchValue !== '' && this.checkboxStatus && this.globalOrderStatus !== '') {
          this.rows = response.results.filter(data => !data.issueReported);
          this.rows.map(order => {
            const checkBalance = order.orderedItem.some(item => {
              return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
            });
            if (checkBalance) {
              order.showPartialShipBtn = true
            } else {
              order.showPartialShipBtn = false;
            }
          });
        } else {
          this.rows = response.results;
          this.rows.map(order => {
            const checkBalance = order.orderedItem.some(item => {
              return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
            });
            if (checkBalance) {
              order.showPartialShipBtn = true
            } else {
              order.showPartialShipBtn = false;
            }
          });
        }
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          if (status === 'new') {
            this.message = 'There are currently no new orders to process'
          } else if (status === 'pending') {
            this.message = 'There are currently no orders pending';
          } else if (status === 'paymentProcess') {
            this.message = 'There are currently no orders available for payment processing';
          } else if (status === 'dispense') {
            this.message = 'There are currently no orders available for dispensing';
          } else if (status === 'completed') {
            this.message = 'There are currently no orders marked as completed';
          } else if (status === 'cancelled') {
            this.message = 'There are currently no orders that have been canceled';
          } else if (status === 'refund') {
            this.message = 'There are currently no orders in refund';
          } else if (status === 'pendingShipment') {
            this.message = 'There are currently no orders in pendingShipment';
          } else if (status === 'partialShipment') {
            this.message = 'There are currently no orders in partial shipment';
          }
        }
        this.rows.sort((a, b) => {
          const dateA = moment(a.orderDate);
          const dateB = moment(b.orderDate);

          if (dateA.isBefore(dateB)) {
            return 1;
          } else if (dateA.isAfter(dateB)) {
            return -1;
          } else {
            return 0;
          }
        });

      });
    } else {
      this.search.type = 'order';
      this.downloadAllCheck = false;
      this.changeColor = false;
      this.shippedOrders = [];
      if (status === 'dispense') {
        this.hiddeShipBtn = true;
        this.downloadOrders = [];
      } else {
        this.hiddeShipBtn = false;
      }
      this.globalOrderStatus = status;
      if (status !== 'pendingShipment') {
        this.selectedCategory = 'Filter';
      }
      this.authService.fetchOrders(this.userData, status, this.page1, localStorage.getItem('pharmacyId')).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.maxPageSize = resp.totalPages;
          if (status === 'dispense') {
            this.downloadOrders = [];
          } else {
            this.downloadOrders = resp.results;
          }
          this.rows = resp.results;
          this.rows.map(order => {
            const checkBalance = order.orderedItem.some(item => {
              return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
            });
            if (checkBalance) {
              order.showPartialShipBtn = true
            } else {
              order.showPartialShipBtn = false;
            }
          });
          if (this.rows.length === 0) {
            if (status === 'new') {
              this.message = 'There are currently no new orders to process'
            } else if (status === 'pending') {
              this.message = 'There are currently no orders pending';
            } else if (status === 'paymentProcess') {
              this.message = 'There are currently no orders available for payment processing';
            } else if (status === 'dispense') {
              this.message = 'There are currently no orders available for dispensing';
            } else if (status === 'completed') {
              this.message = 'There are currently no orders marked as completed';
            } else if (status === 'cancelled') {
              this.message = 'There are currently no orders that have been canceled';
            } else if (status === 'refund') {
              this.message = 'There are currently no orders in refund';
            } else if (status === 'pendingShipment') {
              this.message = 'There are currently no orders in pending shipment';
            } else if (status === 'partialShipment') {
              this.message = 'There are currently no orders in partial shipment';
            }
          }

          this.rows.sort((a, b) => {
            const dateA = moment(a.orderDate);
            const dateB = moment(b.orderDate);

            if (dateA.isBefore(dateB)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          });


          this.tempOrders = this.rows;
          if (this.selectedCategory === 'Pick Up') {
            const temp = this.rows.filter(data => data.pickup);
            this.rows = temp;
          }
        }
      });
    }
  }

  update(status: string, order: any) {
    const body = [{
      propName: 'status', value: status
    }]
    this.authService.updateOrderStatus(this.userData, body, order).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      }
    });
  }

  openReceiptModal1(receiptModal, order, size, modalName = 'parentModal') {
    this.modalName = modalName;
    if (modalName === 'InsideViewModal') {
      this.carrierForm.reset();
      this.submitted = false;
      this.isPartialShipment = !!this.selectedOrder.partialShipment;
      this.modalService.open(receiptModal, {size: size, backdrop: 'static'})
    } else {
      this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
        this.selectedOrder = response;
        this.isPartialShipment = !!this.selectedOrder.partialShipment;
        this.carrierForm.reset();
        this.submitted = false;
        this.modalService.open(receiptModal, {size: size, backdrop: 'static'})
      });
    }
  }

  openModal(content, order: any) {
    this.selectedCustomer = order.userId;
    this.dateOfBirth = moment(order.userId.dateOfBirth).format('DD-MM-YYYY');
    this.selectedOrder = order;
    let selectedPharmacy = localStorage.getItem('pharmacyId')
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, selectedPharmacy).then((resp: any) => {
      this.pharmacyUser = resp;
      if (this.pharmacyUser.user_address_verified && this.pharmacyUser.Status === 'unverified') {
        this.btnTitle = 'Verify User';
        this.btns = true;
      } else if (!this.pharmacyUser.user_address_verified && this.pharmacyUser.Status === 'unverified') {
        this.btnTitle = 'Verify User & Address';
        this.btns = true;
      } else if (!this.pharmacyUser.user_address_verified && this.pharmacyUser.Status === 'verified') {
        this.btnTitle = 'Verify Address';

        this.btns = false;
      } else {
        this.btnTitle = 'Verify'
        this.btns = true;
      }
      this.licenseFront = atob(this.pharmacyUser.licenseFront_temp_encrypted);
      this.licenseBack = atob(this.pharmacyUser.licenseBack_temp_encrypted);
      this.medicareLicense = atob(this.pharmacyUser.medicareCardNumber);
      this.pensionCardNumber = atob(this.pharmacyUser.pensionCardNumber);
    });
    this.modalService.open(content, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openDispenseModal(content) {
    this.disablebtn = false;
    this.tempRecordArray = [];
    if (this.shippedOrders.length > 0) {
      this.shippedOrders.filter((order) => {
      });
      this.modalService.open(content, {size: 'max', backdrop: 'static'});
    }
    this.shippedOrders.forEach((order, index) => {
      this.tempRecordArray.push({
        orderId: order._id,
        orderedItem: []
      });
    });
  }


  openDispneseModal1(content, Order) {
    this.disablebtn = false;
    this.shippedOrders = [];
    this.tempRecordArray = [];
    if (this.shippedOrders.length > 0) {
      this.shippedOrders.push(Order);
      this.shippedOrders.filter((order) => {
        this.tempRecord.orderId = order._id;
        this.tempRecordArray.push(this.tempRecord);
        this.tempRecord = {
          'orderId': '', 'orderedItem': []
        }
      });
    } else {
      this.shippedOrders.push(Order);
      this.shippedOrders.filter((order) => {
        this.tempRecord.orderId = order._id;
        this.tempRecordArray.push(this.tempRecord);
        this.tempRecord = {
          'orderId': '', 'orderedItem': []
        }
      });
    }
    this.modalService.open(content, {size: 'max', backdrop: 'static'});
  }

  closeRestModal(modal) {
    this.createdDivs.filter(data => data._id !== '');
    this.shippedOrders = [];
    if (this.modalName === 'InsideViewModal') {
      this.currentPage();
    }
    modal.dismiss('Cross-Click');
  }

  fillDetails(Order, medi, repeats, duration) {
    if (Number(repeats) < 0) {
      this.toastr.error('Invalid repeats', 'Error', {
        positionClass: 'toast-top-center'
      });
      // checkbox.checked = false;
      return;
    }
    if (Number(duration) < 0) {
      this.toastr.error('Invalid Duration', 'Error', {
        positionClass: 'toast-top-center'
      });
      // checkbox.checked = false;
      return;
    }


    this.rowSelected.selected = medi.product_name;
    this.token_updation.token = medi.token;
    this.token_updation.duration = duration === '' ? 0 : duration;
    this.token_updation.repeats = repeats === '' ? 0 : repeats;
    this.token_updation.scriptData = medi.scriptData;


    if (duration !== '' && repeats !== '') {
      // this.tempRecordArray.push({
      //   orderId: Order._id, orderedItem: []
      // })
      for (let i = 0; i < this.tempRecordArray.length; i++) {
        if (this.tempRecordArray[i].orderId === Order._id) {
          if (this.tempRecordArray[i].orderedItem.length > 0) {
            this.tempRecordArray[i].orderedItem.map(data => {
              if (data.token === medi.token) {
                data.repeats = repeats;
                data.duration = duration;
              }
            })
          } else {
            this.tempRecordArray[i].orderedItem.push(this.token_updation);
          }
        }
      }

      this.token_updation = {
        'repeats': '',
        'token': '',
        'duration': '',
        'scriptData': {
          'uploadedScript': '', 'type': ''
        }
      }
    } else {
      this.tempRecordArray.filter((data) => {
        if (data.orderId === Order._id) {
          const temp = data.orderedItem.filter((item) => {
            return item.token !== medi.token;
          });
          data.orderedItem = temp;
          return data;
        } else {
          return data;
        }
      });

      this.token_updation = {
        'repeats': '',
        'token': '',
        'duration': '',
        'scriptData': {
          'uploadedScript': '', 'type': ''
        }
      }
    }
  }

  changeDob(date: any) {
    this.dateOfBirth = moment(this.monthpipe.transform(date)).format();
  }


  addShippedDate(date: any) {
    this.shippedDate = moment(this.monthpipe.transform(date)).format();
  }


  verifyCustomer(status, modal) {
    const body = {
      'status': status
    }
    this.authService.update_user(this.selectedCustomer, this.userData, body).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('User verification failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Customer ' + status, 'Updated', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        modal.dismiss('Cross-Click');
        this.modalService.dismissAll();
      }
    }).catch(err => {
    });
  }

  blackListCustomer() {
    const body = [
      {
        'propName': 'Status',
        'value': 'blackListed'
      }
    ]
    this.authService.update_user(this.selectedCustomer, this.userData, body).then(resp => {
      this.toastr.error('Customer blacklisted', 'Updated', {
        positionClass: 'toast-top-center'
      });
    }).catch(err => {
    });
    this.modalService.dismissAll();

  }

  ViewOrder(order: any, content, modalName = 'parentModal') {
    this.active = 1;
    this.expand = false;
    this.disablebtn = false;
    this.modalName = modalName;
    this.showForm = false;
    this.partialShippments = [];
    this.orderedItems = [];
    this.pharmacyNotes = [];
    this.customerNotes = [];
    this.showForm = false;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.shippedOrders = [];
      const checkBalance = response.orderedItem.some(item => {
        return item.remainingQuantity > 0 || item.remainingQuantity === undefined;
      });
      if (checkBalance) {
        this.selectedOrder.showPartialShipBtn = true
      } else {
        this.selectedOrder.showPartialShipBtn = false;
      }
      this.createdDivs = [];
      this.disablebtn = false;

      this.pharmacyUser = response.userId;
      this.rows.map((data) => data.checked = false);
      this.isCollapsed = true;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.deliverCharge = response?.pharmacyId.freightCharge
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.createdDivs = this.selectedOrder.notes.length > 0 ? this.selectedOrder.notes : [];
      // this.createdDivs = this.createdDivs.filter((data) => {
      //   return typeof data !== 'number';
      // });
      this.createdDivs = this.createdDivs.sort((a, b) => {
        if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
          return 1;
        } else {
          return -1;
        }
      });
      this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
      this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      this.createdDivs.map((data) => {
        data.disabled = true;
        return data;
      });
      this.orderedItems = order.orderedItem;
      if (this.selectedOrder.status === 'pendingShipment') {
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
          data.shipped = data.shippedQuantity;
          data.balance = data.remainingQuantity;
          data.tempbalance = data.remainingQuantity;
          data.checked = false;
        });
      } else {
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
          data.shipped = 0;
          data.balance = data.remainingQuantity;
          data.tempbalance = data.remainingQuantity;
          data.checked = false;
        });
      }
      if (modalName === 'parentModal') {
        this.modalService.open(content, {windowClass: 'full-screen-modal', backdrop: 'static'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        this.modalService.open(content, {size: 'max', backdrop: 'static'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    });

  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }


  refreshSpecificOrder() {
    this.authService.getOrder_by_Id(this.userData, this.selectedOrder._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.shippedOrders = [];
      const checkBalance = response.orderedItem.some(item => {
        return item.remainingQuantity > 0 || item.remainingQuantity === undefined;
      });
      if (checkBalance) {
        this.selectedOrder.showPartialShipBtn = true
      } else {
        this.selectedOrder.showPartialShipBtn = false;
      }
      this.createdDivs = [];
      this.disablebtn = false;
      this.pharmacyUser = response.userId;
      this.rows.map((data) => data.checked = false);
      this.isCollapsed = true;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(response.uploadedScript) ? true : false;
      this.deliverCharge = response?.pharmacyId.freightCharge
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.createdDivs = this.selectedOrder.notes.length > 0 ? this.selectedOrder.notes : [];
      this.createdDivs = this.createdDivs.filter((data) => {
        return typeof data !== 'number';
      });
      this.createdDivs = this.createdDivs.sort((a, b) => {
        if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
          return 1;
        } else {
          return -1;
        }
      });
      this.createdDivs.map((data) => {
        data.disabled = true;
        return data;
      });
      this.orderedItems = response.orderedItem;
      if (this.selectedOrder.status === 'pendingShipment') {
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
          data.shipped = data.shippedQuantity;
          data.balance = data.remainingQuantity;
          data.tempbalance = data.remainingQuantity;
          data.checked = false;
        });
      } else {
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
          data.shipped = 0;
          data.balance = data.remainingQuantity;
          data.tempbalance = data.remainingQuantity;
          data.checked = false;
        });
      }
    });
  }


  sendDispense_Orders(modal: any) {
    this.moveToShipped(modal)
  }


  currentPage() {
    if (this.search.type === 'filter') {
      this.globalOrderStatus = !this.checkboxStatus && this.globalOrderStatus === '' ? 'new' : !this.checkboxStatus && this.globalOrderStatus !== '' ? this.globalOrderStatus : '';
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page1, this.globalOrderStatus).then((response: any) => {
        this.rows = response.results.filter((data) => {
          return !data.issueReported;
        });
        this.rows.map(order => {
          const checkBalance = order.orderedItem.some(item => {
            return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
          });
          if (checkBalance) {
            order.showPartialShipBtn = true
          } else {
            order.showPartialShipBtn = false;
          }
        });
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          if (this.globalOrderStatus === 'new') {
            this.message = 'There are currently no new orders to process'
          } else if (this.globalOrderStatus === 'pending') {
            this.message = 'There are currently no orders pending';
          } else if (this.globalOrderStatus === 'paymentProcess') {
            this.message = 'There are currently no orders available for payment processing';
          } else if (this.globalOrderStatus === 'dispense') {
            this.message = 'There are currently no orders available for dispensing';
          } else if (this.globalOrderStatus === 'shipped') {
            this.message = 'There are presently no orders available for shipping';
          } else if (this.globalOrderStatus === 'completed') {
            this.message = 'There are currently no orders marked as completed';
          } else if (this.globalOrderStatus === 'cancelled') {
            this.message = 'There are currently no orders that have been canceled';
          } else if (this.globalOrderStatus === 'refund') {
            this.message = 'There are currently no orders in refund';
          } else if (this.globalOrderStatus === 'issues') {
            this.message = 'There are currently no orders in issues';
          }
        }
        this.rows.sort((a, b) => {
          if (moment(moment(a.orderDate).format(), 'L').isBefore(moment(moment(b.orderDate).format(), 'L'))
            && moment(moment(a.orderDate).format(), 'LT').isBefore(moment(moment(b.orderDate).format(), 'LT'))) {
            return -1;
          } else {
            return 1;
          }
        });
      });
    } else {
      this.authService.fetchOrders(this.userData, this.globalOrderStatus, this.page1, localStorage.getItem('pharmacyId')).then((resp: any) => {
        this.rows = resp.results.filter((data) => {
          return !data.issueReported
        });
        this.rows.map(order => {
          const checkBalance = order.orderedItem.some(item => {
            return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
          });
          if (checkBalance) {
            order.showPartialShipBtn = true
          } else {
            order.showPartialShipBtn = false;
          }
        });
        this.maxPageSize = resp.totalPages;
        // this.downloadOrders = this.rows;
      });
    }
  }

  updateProduct(modal) {
    let body = [];

    for (const key in this.editProd.controls) {
      if (key === 'price' && this.editProd.controls[key].dirty) {
        body.push({
          propName: 'orderedItem.' + this.index + '.' + 'price',
          value: Number(this.editProd.controls['price'].value)
        });
      } else if (key === 'product_name' && this.editProd.controls[key].dirty) {
        body.push({propName: 'orderedItem.' + this.index + '.' + key, value: this.editProd.controls[key].value});
        body.push({propName: 'orderedItem.' + this.index + '.' + 'isBackOrder', value: this.selectedProduct.stock <= 0});
        body.push({
          propName: 'orderedItem.' + this.index + '.' + 'price',
          value: Number(this.editProd.controls['price'].value)
        });
      } else if (this.editProd.controls[key].dirty) {
        body.push({propName: 'orderedItem.' + this.index + '.' + key, value: this.editProd.controls[key].value});
      }
    }
    if (body.length === 0) {
      this.toastr.error('Edit any of the fields', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.error('Ordered items udpation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Ordered Items Updated', 'Updated', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.selectedOrder = resp.updatedBody;
        this.orderedItems = this.selectedOrder.orderedItem;
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
        });
        this.editedOrder = true;
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.error('Ordered items udpation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  removeItem(data: any) {
    const res = this.orderedItems.filter((item) => {
      return item.product_name !== data.product_name;
    });
    this.orderedItems = res;

  }

  async acceptOrder(Orderstatus) {
    if (this.selectedOrder.userId.pharmacyUserStatus === 'unverified') {
      this.toastr.info('Please Verify User', 'Info!', {
        positionClass: 'toast-top-center'
      });
    } else {
      const body = {
        'status': Orderstatus
      }
      this.authService.accept_Order(this.userData, this.selectedOrder, body).then((resp: any) => {
        if (resp.status === 404) {
          this.toastr.error(resp.error.message, 'Error!', {
            positionClass: 'toast-top-center'
          });
          return;
        } else if (resp.status === 500) {
          this.toastr.error('Failed to accept the order', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 400) {
          this.toastr.error(resp.error.message, 'Error!', {
            positionClass: 'toast-top-center'
          });
          return;
        } else if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Orders accepted Successfully', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.currentPage();
        }
      }).catch(err => {
        this.toastr.error('Failed to accept the order', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }


  confirmation_modal(modal, message, order) {
    this.selectedOrder = order;
    this.selectedCustomer = order.userId;
    this.confirmationMessage = message;
    this.modalService.open(modal, {size: 'md', backdrop: 'static'});
  }


  acceptOrderForm(status, modal) {
    if (this.selectedOrder.userId.pharmacyUserStatus === 'unverified' || !this.selectedOrder.userId.user_address_verified) {
      this.toastr.info('Please Verify the User', 'Info!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.editedOrder) {
      const body = {
        status: status
      }
      this.authService.accept_Order(this.userData, this.selectedOrder, body).then((resp: any) => {
        if (resp.status === 404) {
          this.toastr.error(resp.error.error, 'Error!', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 400) {
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.toastr.error('Failed to accept the order', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Orders accepted Successfully', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.currentPage();
        }
      }).catch(err => {
      })
    } else {
      this.acceptManual(modal, this.selectedOrder);
    }
    modal.dismiss('Cross-Click');
    this.modalService.dismissAll();
  }


  cancelOrder(textbox: any) {
    this.submitted = true;
    if (textbox.value === '') {
      this.addReson = 'Reason field is required';
      return;
    }

    if (!/^(?!\s).*$/.test(textbox.value)) {
      this.addReson = 'Please ensure there are no spaces at the beginning or end of the text';
      return;
    }

    this.addReson = '';
    const body = [{
      propName: 'rejectReason',
      value: textbox.value
    }, {
      propName: 'status', value: 'reject'
    }];

    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.info('Ordered cancelled Successfully', 'Info', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        this.submitted = false;
        this.modalService.dismissAll();
      }
    }).catch(err => {
      this.toastr.error('Order cancelation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    });
    this.modalService.dismissAll();
  }


  refund(reason: any, refundamount: any) {
    if (refundamount.value === '') {
      this.toastr.error('Please Enter the refund amount', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (reason.value === '') {
      this.toastr.error('Please Enter the Reason for order cancellation', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (Number(refundamount.value) < 0) {
      this.toastr.error('Invalid refund amount', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = {
      rejectReason: reason.value,
      refundableAmount: refundamount.value
    };
    this.authService.refund_Order(this.userData, this.selectedOrder, 'initiated', body).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error('invalid refund status type', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Refund Initiated Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        this.modalService.dismissAll();
      }
    }).catch(err => {
    });
  }


  moveToCancel(modal) {
    this.authService.refund_Order(this.userData, this.selectedOrder, 'completed', {}).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error('invalid refund status type', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Refund Initiated Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
    });
  }

  cancelVerification() {
    this.modalService.dismissAll();
  }

  onChangeProduct(event: any, modal) {
    if (this.selectedStep === 'step-2') {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
      }
      if (this.Medicinerows.length > 0) {
        const check = this.Medicinerows.some((d: any) => {
          return d._id === this.selectedProduct._id;
        });

        if (check) {
          this.toastr.clear();
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
          return;
        }
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      } else {
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      }
    } else {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
      }
      if (this.orderedItems.length > 0) {
        const check = this.orderedItems.some((d: any) => {
          return d.product_name === this.selectedProduct.productName;
        });

        if (check) {
          this.toastr.clear();
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
          return;
        }
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      } else {
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      }
    }
    modal.dismiss('Cross-Click');
  }

  updateProdDetails(event, modal) {
    this.selectedProduct = event;
    if (this.selectedProduct.stock <= 0) {
      this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
        positionClass: 'toast-top-center',
      });
    }
    this.editProd.controls['price'].setValue(event.productCost);
  }

  openConfirmModal(confirmModal: any, order: any) {
    this.addReson = '';
    this.selectedOrder = order
    this.modalService.open(confirmModal, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  pushMe(order, event: any) {
    if (event.target.checked) {
      order.orderedItem.filter((data) => {
        data.selected = false;
        return data;
      })
      this.shippedOrders.push(order);
      this.downloadOrders.push(order);

      const t = this.rows.filter((data) => {
        if (order._id === data._id) {
          data.checked = true;
        }
        return data;
      });
      this.rows = t;

    } else {
      const t = this.rows.filter((data) => {
        if (order._id === data._id) {
          data.checked = false;
        }
        return data;
      });
      this.rows = t;

      const arr = this.shippedOrders.filter(ele => {
        return order._id !== ele._id;
      });
      this.shippedOrders = arr;
      const arr1 = this.downloadOrders.filter(ele => {
        return order._id !== ele._id;
      });
      this.downloadOrders = arr1;
    }
  }

  moveToShipped(modal) {
    const check = this.tempRecordArray.every((data) => {
      return data.orderedItem.length === 0;
    });
    if (check) {
      this.toastr.clear();
      this.toastr.error('Please add the items to move the order to dispense', 'Success', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      const arrayCheck = this.tempRecordArray.filter((data) => {
        return data.orderedItem.length !== 0;
      });
      this.tempRecordArray = arrayCheck;
    }

    if (this.tempRecordArray.length === 0) {
      this.toastr.error('Please tick the checkbox to move the order to dispense', 'Success', {
        positionClass: 'toast-top-center'
      });
      this.tempRecord = {
        'orderId': '', 'orderedItem': [],
      };
      this.tempRecordArray.push(this.tempRecord);
      return;
    }
    this.disablebtn = true;
    this.authService.move_To_shipped(this.userData, this.tempRecordArray).then((resp) => {
      if (resp.status === 500) {
        this.disablebtn = false;
        this.toastr.error('Orders shipping failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Orders Shipped', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.showSubmitbtn = false;
        this.currentPage();
        this.shippedOrders = [];
        this.tempRecordArray = [];
        this.Token_updation_body = [];
        this.enable = false;
        this.disablebtn = false;
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.disablebtn = false;
      this.toastr.error('Orders shipping failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  downloadAll(event) {
    if (event.target.checked) {
      this.rows.map((order) => order.checked = true)
      this.downloadAllCheck = true;
      this.downloadOrders = this.rows;
      this.shippedOrders = this.rows;
    } else {
      this.rows.map((order) => order.checked = false)
      this.downloadAllCheck = false;
      this.downloadOrders = [];
      this.shippedOrders = [];
    }

  }

  downloadOrdersList() {
    if (this.globalOrderStatus === 'dispense') {
      if (this.downloadOrders.length === 0) {
        return;
      }

      const temp = [];
      let medicationBody = {
        'tokens': '', 'medication': '', 'quantity': '', 'repeats': ''
      }
      let body: any = {}
      this.downloadOrders.filter(order => {
        body.orderNo = order.orderNo;
        body.orderBy = order.name;

        order.orderedItem.filter((data) => {
          medicationBody.tokens += '' + data.token + '\n';
          medicationBody.medication += '' + data.product_name + '\n';
          medicationBody.quantity += '' + data.quantity + '\n';
          medicationBody.repeats += '' + data.repeats + '\n';
        })
        body.Token = medicationBody.tokens;
        body.Medication = medicationBody.medication;
        body.Quantity = medicationBody.quantity;
        body.Repeats = medicationBody.repeats;
        body.Period = ''
        temp.push(body);
        body = {};
        medicationBody = {
          'tokens': '', 'medication': '', 'quantity': '', 'repeats': ''
        }
      });
      this.jsonToXlsxService.convertJsonToXlsx(temp, 'Sheet 1', 'exported_data');

    } else {
      if (this.downloadOrders.length === 0) {
        return;
      }
      const temp = [];
      let medicationBody = {
        'tokens': '', 'medication': '', 'quantity': '', 'repeats': '', 'BrandName': '', 'instructions': '', 'notes': ''
      }
      let body: any = {}
      this.downloadOrders.filter(order => {
        body.orderNo = order.orderNo;
        body.orderBy = order.name;
        body.email = order.email;
        body.phoneNumber = order.mobile.toString();
        body.orderStatus = order.status;
        body.pharmacy_name = order.pharmacyId.pharmacy_name;
        body.address = order.address;
        body.suburb = order.suburb;
        body.state = order.state;
        body.postcode = order.postcode;
        order.orderedItem.filter((data) => {
          medicationBody.tokens += '' + data.token + '\n';
          medicationBody.BrandName += '' + data.product_brand_name + '\n';
          medicationBody.medication += '' + data.product_name + '\n';
          medicationBody.quantity += '' + data.quantity + '\n';
          medicationBody.repeats += '' + data.repeats + '\n';
          medicationBody.instructions += '' + data.instruction + '\n';
          medicationBody.notes += '' + data.note + '\n';
        });
        body.Token = medicationBody.tokens;
        body.BrandName = medicationBody.BrandName;
        body.Medication = medicationBody.medication;
        body.Quantity = medicationBody.quantity;
        body.Repeats = medicationBody.repeats;
        body.Instructions = medicationBody.instructions;
        body.Notes = medicationBody.notes;
        body.Period = ''
        body.totalAmount = order.totalAmount;
        body.orderPayment_status = order.orderPayment_status;
        temp.push(body);
        body = {};
        medicationBody = {
          'tokens': '', 'medication': '', 'quantity': '', 'repeats': '', 'BrandName': '', 'instructions': '', 'notes': ''
        }
      });
      this.jsonToXlsxService.convertJsonToXlsx(temp, 'Sheet 1', 'exported_data');
    }


  }


  openReceiptModal(recepiptModal: any, order, modalType = 'paymentModal', extraCharge = 0, index = -1) {
    this.disablebtn = false;
    this.submitted = false;
    this.enableReadOnly = true;
    this.modalType = modalType;
    this.selectedIndex = index;
    this.receiptAcc.reset();
    if (modalType === 'paymentModal') {
      this.receiptAcc.controls['orderAmount'].setValue(order.totalAmount);
      this.submitted = false;
      this.model = null;
      this.shippedDate = ''
      this.selectedOrder = order;
      this.modalService.open(recepiptModal, {size: 'md', backdrop: 'static'});
    } else {
      this.receiptAcc.controls['orderAmount'].setValue(String(extraCharge));
      this.modalService.open(recepiptModal, {size: 'md', backdrop: 'static'});
    }

  }

  async OnSubmitReceipt(recepiptModal) {
    this.submitted = true;
    if (this.receiptAcc.invalid) {
      return;
    }

    // if (this.model !== null) {
    //   if (moment(this.model, 'DD-MM-YYYY').format() === 'Invalid date') {
    //     this.shippedDate = moment(this.monthpipe.transform(this.model)).format();
    //   } else {
    //     this.shippedDate = moment(this.model, 'DD-MM-YYYY').format();
    //   }
    // }


    const body = {
      'orderPayment_method': this.receiptAcc.value.orderPayment_method,
      'orderPayment_reference': this.receiptAcc.value.orderPayment_reference,
      'orderAmount': this.receiptAcc.value.orderAmount,
      'orderPayment_status': 'accepted',
    }
    this.disablebtn = true;
    if (this.modalType === 'extraChargeModal') {
      if (!this.enableReadOnly) {
        const updateAmount = [{
          propName: 'additionalCosts.' + this.selectedIndex + '.amount', value: Number(this.receiptAcc.value.orderAmount)
        }];
        this.authService.update_order(this.userData, updateAmount, this.selectedOrder).then(async (updateOrder) => {
          this.selectedOrder = updateOrder.updatedBody;
          await this.authService.sendReciept(this.userData, body, this.selectedOrder,
            this.selectedOrder.numberOfTimePaymentAccepted === 0, true).then(async (resp: any) => {
            if (resp.status === 400) {
              this.toastr.error(resp.error.message, 'Error', {
                positionClass: 'toast-top-center'
              });
              this.disablebtn = false;
              // this.acceptOrder('accepted');
              recepiptModal.dismiss('Cross-Click');
            } else if (resp.status === 404) {
              this.toastr.error(resp.error.message, 'Error', {
                positionClass: 'toast-top-center'
              });
              this.disablebtn = false;
            } else if (resp.status === 403) {
              this.toastr.error(resp.error.message, 'Error', {
                positionClass: 'toast-top-center'
              });
              this.disablebtn = false;
            } else {
              this.toastr.success('order payment Status updated successfully', 'Success', {
                positionClass: 'toast-top-center'
              });
              if (this.selectedOrder.numberOfTimePaymentAccepted === 0) {
                await this.acceptOrder('accepted');
              }
              this.disablebtn = false;
              this.currentPage();
              this.submitted = false;
              this.receiptAcc.reset();
              recepiptModal.dismiss('Cross-Click');
            }
          }).catch(err => {
            this.disablebtn = false;
            if (err.status === 400) {
              this.toastr.error(err.error.message, 'Error', {
                positionClass: 'toast-top-center'
              });
              this.disablebtn = false;
              // this.acceptOrder('accepted');
              recepiptModal.dismiss('Cross-Click');
            } else {
              this.toastr.error(err.error.message, 'Error', {
                positionClass: 'toast-top-center'
              });
            }
            recepiptModal.dismiss('Cross-Click');
          });
        });
      } else {
        await this.authService.sendReciept(this.userData, body, this.selectedOrder,
          this.selectedOrder.numberOfTimePaymentAccepted === 0, true).then(async (resp: any) => {
          if (resp.status === 400) {
            this.toastr.error(resp.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            this.disablebtn = false;
            // this.acceptOrder('accepted');
            recepiptModal.dismiss('Cross-Click');
          } else if (resp.status === 404) {
            this.toastr.error(resp.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            this.disablebtn = false;
          } else if (resp.status === 403) {
            this.toastr.error(resp.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            this.disablebtn = false;
          } else {
            this.toastr.success('order payment Status updated successfully', 'Success', {
              positionClass: 'toast-top-center'
            });
            if (this.selectedOrder.numberOfTimePaymentAccepted === 0) {
              await this.acceptOrder('accepted');
            }
            this.disablebtn = false;
            this.currentPage();
            this.submitted = false;
            this.receiptAcc.reset();
            recepiptModal.dismiss('Cross-Click');
          }
        }).catch(err => {
          this.disablebtn = false;
          this.toastr.error('Internal Server Error', 'Error', {
            positionClass: 'toast-top-center'
          });
          recepiptModal.dismiss('Cross-Click');
        });
      }
    } else {
      if (this.receiptAcc.value.orderAmount < this.selectedOrder.totalAmount) {
        this.validation = true;
        return;
      }
      this.validation = false;
      this.authService.sendReciept(this.userData, body, this.selectedOrder,
        this.selectedOrder.numberOfTimePaymentAccepted === 0).then(async (resp: any) => {
        if (resp.status === 400) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.disablebtn = false;
          // this.acceptOrder('accepted');
          recepiptModal.dismiss('Cross-Click');
        } else if (resp.status === 404) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.disablebtn = false;
        } else if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.disablebtn = false;
        } else {
          this.toastr.success('order payment Status updated successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          // if (this.shippedDate !== '') {
          //   const updateShippedDate = [{
          //     'propName': 'shipDate', 'value': this.shippedDate
          //   }];
          //   this.authService.update_order(this.userData, updateShippedDate, this.selectedOrder).then(() => {
          //     this.toastr.success('Shipped Date Updated Successfully', 'Success', {
          //       positionClass: 'toast-top-center'
          //     });
          //     this.model = null;
          //   }).catch(err => {
          //     this.toastr.error('Failed to update the shipped Date', 'Success', {
          //       positionClass: 'toast-top-center'
          //     });
          //   })
          // }
          if (this.selectedOrder.numberOfTimePaymentAccepted === 0) {
            await this.acceptOrder('accepted');
          }
          this.disablebtn = false;
          this.currentPage();
          this.submitted = false;
          this.receiptAcc.reset();
          recepiptModal.dismiss('Cross-Click');
        }
      }).catch(err => {
        this.disablebtn = false;
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
        recepiptModal.dismiss('Cross-Click');
      });
    }
    this.modalService.dismissAll();
  }

  openCreateOdrModal(content, size) {
    this.type = 'name';
    this.model = null;
    this.submitted = false;
    this.userRegisteration.reset();
    this.lockButton = false;
    this.searchPhone.reset();
    this.showpwd = false;
    this.orderedItems = [];
    this.Medicinerows = [];
    this.customerList = [];
    this.selectedCustomer = undefined;
    this.showAddressForm = false;
    this.modalService.open(content, {size: size, backdrop: 'static'});
  }


  OnChange(event: any) {
    this.type = event.target.value;
    this.submitted = false;
    this.showMessageOnce = 0;
  }

  searchCustomer(type) {

    if (type === 'email') {
      if (!this.searchPhone.value.email) {
        this.toastr.clear();
        this.toastr.error('Please enter your email', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    } else if (type === 'phoneNumber') {
      if (!this.searchPhone.value.phone) {
        this.toastr.clear();
        this.toastr.error('Please enter your phone number', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    } else {
      if (!this.searchPhone.value.name) {
        this.toastr.clear();
        this.toastr.error('Please enter your Name', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    }

    this.deliverTypeSelected = false;
    this.selectedCustomer = undefined;
    this.selectCustomerFlag = false;

    if (type === 'email') {
      this.authService.search_Customer(this.userData, this.searchPhone.value.email, type, this.platform).then((resp: any) => {
        this.showMessageOnce += 1;
        if (resp.status === 404 && this.showMessageOnce === 1) {
          this.customerList = [];
          this.toastr.error('User Not found', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.submitted = false;
          this.customerList = resp.body.filter((cust: any) => {
            cust.convertedDob = moment(cust.dateOfBirth).format('DD-MM-YYYY');
            return cust;
          });
          // this.defaultAddess = this.customerList[0].addresses[0]._id
          this.customerList = resp.body.filter((customer) => {
            customer.addresses.map((address) => {
              address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
            });
            return customer.role === 'User';
          });
        }
      }).catch(err => {
      })
    } else if (type === 'phoneNumber') {
      this.authService.search_Customer(this.userData, '61' + parseInt(this.searchPhone.value.phone, 10), type, this.platform).then((resp: any) => {
        if (resp.status === 404) {
          this.customerList = [];
          this.toastr.clear();
          this.toastr.error('User Not found', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.submitted = false;
          this.customerList = resp.body.filter((cust: any) => {
            cust.convertedDob = moment(cust.dateOfBirth).format('DD-MM-YYYY');
            return cust;
          });
          this.customerList = resp.body.filter((customer) => {
            customer.addresses.map((address) => {
              address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
            });
            return customer.role === 'User';
          });
          this.authService.fetch_pharmacy_user(this.userData, this.customerList[0], localStorage.getItem('pharmacyId')).then((response) => {
            if (response.response === 'No user found with the given criteria') {
              this.authService.createPharmacy_user(this.userData, {
                userId: this.customerList[0]._id,
                pharmacyId: localStorage.getItem('pharmacyId')
              }).then((response1: any) => {
                this.pharmacyUser = response1.pharmacyUser_created;
              })
            } else {
              this.pharmacyUser = response;

              // this.userStatus = this.pharmacyUser.Status;
            }
          })
        }
      }).catch(err => {
      })
    } else {
      this.authService.search_Customer(this.userData, this.searchPhone.value.name, 'firstName',
        this.platform).then((resp: any) => {
        if (resp.status === 404) {
          this.customerList = [];
          this.toastr.clear();
          this.toastr.error('User Not found', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.submitted = false;
          this.customerList = resp.body.filter((cust: any) => {
            cust.convertedDob = moment(cust.dateOfBirth).format('DD-MM-YYYY');
            return cust;
          });
          this.customerList = resp.body.filter((customer) => {
            customer.addresses.map((address) => {
              address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
            });
            return customer.role === 'User';
          });
          this.authService.fetch_pharmacy_user(this.userData, this.customerList[0], localStorage.getItem('pharmacyId')).then((response) => {
            if (response.response === 'No user found with the given criteria') {
              this.authService.createPharmacy_user(this.userData, {
                userId: this.customerList[0]._id,
                pharmacyId: localStorage.getItem('pharmacyId')
              }).then((response1: any) => {
                this.pharmacyUser = response1.pharmacyUser_created;
              })
            } else {
              this.pharmacyUser = response;

              // this.userStatus = this.pharmacyUser.Status;
            }
          })
        }
      }).catch(err => {
      })
    }
  }

  validateText(token) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(token.value)) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  upload(file: any, modal) {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    this.authService.uploadScript_image(this.userData, file.target.files[0]).then((resp: any) => {
      this.toastr.clear();
      this.toastr.success('Script Uploaded Successfully', 'Success!', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      if (this.scriptImage_for === 'for_each_medicine') {
        this.orderedItems[this.selectedMedicine_Index].scriptData.uploadedScript = resp.location;
        this.orderedItems[this.selectedMedicine_Index].scriptData.type = this.uploadType;
      } else {
        this.scriptImage = resp.location;
      }

      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(this.scriptImage) ? true : false;
      modal.dismiss('Cross-Click');
    })
  }


  get rf() {
    return this.userRegisteration.controls;
  }

  async onSubmit(modal, checkBox) {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }

    if (this.userRegisteration.controls['password'].value !== this.userRegisteration.controls['confirm_password'].value) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.model !== null) {
      if (moment(this.model, 'DD-MM-YYYY').format() === 'Invalid date') {
        this.dob = moment(this.monthpipe.transform(this.model)).format();
      } else {
        this.dob = moment(this.model, 'DD-MM-YYYY').format();
      }
    }

    let TACAccepted = false;
    let PSAccepted = false;
    if (!checkBox.checked) {
      this.toastr.error('Please accept the terms and conditions', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      TACAccepted = true;
      PSAccepted = true;
    }

    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName,
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + parseInt(this.userRegisteration.value.number, 10),
      'dateOfBirth': this.dob,
      'PSAccepted': PSAccepted,
      'TACAccepted': TACAccepted,
      'consentAccepted': true,
      'twofaEnabled': true,
      'role': 'User',
      'userType': 'user',
      'platformId': this.platform._id
    }
    this.authService.register_new_user(body).then(async (resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('User already Exits', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.userCreated = resp.userCreated;
        const pharmacyUser = {
          userId: resp.userCreated._id,
          pharmacyId: this.pharmacy._id
        }
        await this.authService.createPharmacy_user(this.userData, pharmacyUser).then((response) => {
          this.pharmacyUser = response.pharmacyUser_created;
          this.userStatus = response.pharmacyUser_created.Status;
        }).catch(err => {
          this.toastr.error('Failed to create Pharmacy User', 'Error!', {
            positionClass: 'toast-top-center'
          });
        })
        const addressBody = {
          userId: this.userCreated._id,
          addressType: this.userRegisteration.value.addressType,
          address: this.userRegisteration.value.address,
          suburb: this.userRegisteration.value.suburb,
          state: this.userRegisteration.value.state,
          // tslint:disable-next-line:radix
          postcode: parseInt(this.userRegisteration.value.postcode)
        }
        await this.authService.create_Address(addressBody).then((addressResp: any) => {

          const updateAddress = [{
            propName: 'user_address',
            value: addressResp.address._id
          }]
          this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, updateAddress).then((result) => {
          })
        });
        this.toastr.clear();
        this.toastr.success('User Registration Successfull', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.submitted = false;
        this.userRegisteration.reset();
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
    modal.dismiss('Cross-Click')
  }


  selectCustomer(customer, id) {
    this.orderedItems = [];
    this.Medicinerows = [];
    this.selectCustomerFlag = id;
    this.selectedCustomer = customer;
    const body = [{
      propName: 'user_address', value: this.selectedCustomer_Address._id
    }];
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, localStorage.getItem('pharmacyId')).then((response) => {
      if (response.message === '') {
      } else {
        this.pharmacyUser = response;
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
      }
    });

  }


  acceptManual(modal, order) {
    this.selectedOrder = order;
    const body = [{
      propName: 'status',
      value: 'paymentProcess'
    }]
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp) => {
      if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success(this.editedOrder ? 'Orders Approved Successfully' : 'Order Accepted Successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    });
    this.modalService.dismissAll()
  }

  SelectedAddress(event: any, customer) {
    this.orderedItems = [];
    this.Medicinerows = [];
    this.selectedCustomer = customer;
    this.selectedCustomer_Address = event;
    const body = [{
      propName: 'user_address', value: this.selectedCustomer_Address._id
    }];
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, localStorage.getItem('pharmacyId')).then((response) => {
      if (response.message === '') {
      } else {
        this.pharmacyUser = response;
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
      }
    });
  }

  add(smallModal, content) {
    this.submitted = true;
    if (this.scriptForm.invalid) {
      return;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const medicineObj = {
      token: this.scriptForm.value.token,
      data: [],
    }
    const medicineData = [];
    this.authService.getMedication_token_scan(this.userData, this.scriptForm.value.token, this.selectedCustomer, this.selectedPharmacy,
      this.orderBy).then((resp: any) => {
      if (resp.status === 400) {
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(resp.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.modalService.open(smallModal, {size: 'md', backdrop: 'static'});
      this.spinner.hide();
      if (resp.IHI_number) {
        const body = [{
          propName: 'Status', value: 'unverified'
        }];
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
        for (const mediData of resp.medications) {
          medicineData.push(mediData);
        }
        medicineObj.data = medicineData;
        if (this.Medicinerows.length > 0) {
          const check = this.Medicinerows.some((item) => {
            return item.token === medicineObj.token;
          });
          if (check) {
            this.toastr.clear();
            this.toastr.error('Token already exits in the medicine List', 'Error', {
              positionClass: 'toast-top-center'
            });
            this.submitted = false;
            this.scriptForm.reset();
            return;
          }
        }
        if (this.productList.length > 0) {
          const res = medicineObj.data.filter((item) => {
            this.productList.map((prod) => {
              if (item.product_name === prod.productName) {
                item.price = prod.price;
              }
            });
            return item;
          });
          medicineObj.data = res;
          this.Medicinerows.push(medicineObj);
        } else {
          this.Medicinerows.push(medicineObj);
        }

      } else {
        for (const mediData of resp) {
          medicineData.push(mediData);
        }
        medicineObj.data = medicineData;
        if (this.Medicinerows.length > 0) {
          const check = this.Medicinerows.some((item) => {
            return item.token === medicineObj.token;
          });
          if (check) {
            this.toastr.error('Token already exits in the medicine List', 'Error', {
              positionClass: 'toast-top-center'
            });
            this.submitted = false;
            this.scriptForm.reset();
            return;
          }
        }

        if (this.productList.length > 0) {
          const res = medicineObj.data.filter((item) => {
            this.productList.map((prod) => {
              if (item.product_name === prod.productName) {
                item.price = prod.price;
              }
            });
            return item;
          });
          medicineObj.data = res;
          this.Medicinerows.push(medicineObj);
        } else {
          this.Medicinerows.push(medicineObj);
        }
      }
    }).catch(err => {
    })
    this.submitted = false;
    this.scriptForm.reset();
    // this.modalService.dismissAll(content)
  }


  remove(content) {
    const res = this.orderedItems.filter((ele) => {
      return ele.product_name !== this.selectedMedicince.productName;
    });
    this.orderedItems = res;
    const remove = this.Medicinerows.filter((ele) => {
      return ele.productName !== this.selectedMedicince.productName;
    });
    this.Medicinerows = remove;
    content.dismiss('Cross-Click')
  }

  // fetch_pharmacy_user() {
  //   this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, this.selectedPharmacy._id).then(resp => {
  //     if (resp.response === 'No user found with the given criteria') {
  //       this.pharmacyUser = resp;
  //     } else {
  //       this.pharmacyUser = resp;
  //       this.frontLicense = atob(this.pharmacyUser.licenseFront_temp_encrypted);
  //       this.backLicense = atob(this.pharmacyUser.licenseBack_temp_encrypted);
  //       this.userStatus = this.pharmacyUser.Status;
  //       if (this.userStatus === 'unverified') {
  //         // nothing till now
  //       }
  //     }
  //   });
  // }


  openDeliveryModal(content, size, deliveryType) {
    if (deliveryType) {
      this.deliverModal.pickup = true;
      this.deliverModal.delivery = false;
    } else {
      this.deliverModal.delivery = true;
      this.deliverModal.pickup = false;
    }

    this.deliverTypeSelected = true;
    this.pickup = deliveryType;
    this.myorderObj = {
      userId: this.selectedCustomer._id,
      pharmacyId: this.selectedPharmacy._id,
      pickup: this.pickup,
      orderedItem: this.orderedItems,
      addressType: this.selectedCustomer_Address.addressType,
      address: this.selectedCustomer_Address?.address || '',
      suburb: this.selectedCustomer_Address?.suburb || '',
      state: this.selectedCustomer_Address?.state || '',
      postcode: this.selectedCustomer_Address?.postcode || '',
      usageId: '65168634b795d0617ab4d84e',
      uploadedScript: this.scriptImage === '' ? '' : this.scriptImage,
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  placeOrder(content) {
    this.myorderObj = {...this.myorderObj, ['deliveryInstruction']: this.delivery_Instruction}
    this.lockButton = true;
    this.choiceMade = ''
    this.authService.createOrder(this.userData, this.myorderObj).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 400) {
        this.toastr.clear();
        this.lockButton = false;
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 404) {
        this.toastr.clear();
        this.lockButton = false;
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Order placed', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll(content);
        this.loadOrders();
      }
    }).catch(err => {
      if (err.status === 400) {
        this.toastr.clear();
        this.lockButton = false;
        this.toastr.error(err.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.lockButton = false;
        this.toastr.clear();
        this.toastr.error('Failed to placed the Order', 'Error', {
          positionClass: 'toast-top-center'
        });
      }
      this.lockButton = false;
      this.modalService.dismissAll(content);
    })
  }

  confirmation(content, size) {
    if (this.orderedItems.length === 0) {
      this.toastr.clear();
      this.toastr.warning('Kindly choose the medication by ticking the checkbox.', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.deliverTypeSelected) {
      this.toastr.clear();
      this.toastr.info('Please Select the Delivery Type to place the Order', 'Info!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const check = this.orderedItems.some((data) => {
      return data.token === '' || data.repeats === 0 || data.repeats === '';
    });
    if (check) {
      this.toastr.clear();
      this.toastr.error('Please fill the token and repeats for the selected Products', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.open1(content, size, 'step-2');
  }

  confirm(deliverIns: string, d) {
    if (this.pickup) {
      d.dismiss('Cross click');
    } else {
      this.delivery_Instruction = deliverIns;
      d.dismiss('Cross click');
    }
  }

  open1(content, size, step = 'step-2') {
    this.selectedStep = step;
    this.scriptImage_for = 'for_complete_order';
    this.uploadType = '';
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.deliverModal = {
      delivery: false,
      pickup: false
    }
  }

// add script image for medicines
  AddScriptImage(modal, size, index) {
    this.uploadType = '';
    this.scriptImage_for = 'for_each_medicine';
    this.selectedMedicine_Index = index;
    this.modalService.open(modal, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  validateToken(value: string) {
    const pattern = /^(?!\s).*$/;
    if (value.trim() === '') {
      this.addReson = 'Reason is required';
      return;
    }
    if (!pattern.test(value.trim())) {
      this.addReson = 'Please ensure there are no spaces at the beginning or end of the text';
    } else {
      this.addReson = '';
    }
  }

  increase(Item, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((item) => {
        if (Item._id === item._id) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });

      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.productName === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.stock) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.productName) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.stock) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    }
  }

  decrease(Item, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((item) => {
        if (Item._id === item._id) {
          if (item.quantity > 1) {
            item.quantity = item.quantity - 1;
          }

        }
      });
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.productName === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.productName) {
          if (item.quantity > 1) {
            item.quantity = item.quantity - 1;
          }
        }
      });

      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
    }
  }

  submitAddress(modal) {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }
    const body = {
      userId: this.userCreated._id,
      address: this.userRegisteration.value.address,
      suburb: this.userRegisteration.value.suburb,
      state: this.userRegisteration.value.state,
      postcode: parseInt(this.userRegisteration.value.postcode)
    }
    this.authService.create_Address(body).then((resp: any) => {

      const updateAddress = [{
        propName: 'user_address',
        value: resp.address._id
      }]
      this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, updateAddress).then((result) => {
      });
      this.toastr.clear();
      this.toastr.info('Address created successfully', 'Updated', {
        positionClass: 'toast-top-center'
      });
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Failed to create the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
    modal.dismiss('Cross-Click');
    this.userRegisteration.reset();
    this.submitted = false;
  }

  close_reset(modal: any) {
    this.lockButton = false;
    this.userRegisteration.reset();
    this.deliverTypeSelected = false;
    this.searchPhone.reset();
    this.Medicinerows = [];
    this.customerList = [];
    this.selectedCustomer = undefined;
    this.selectCustomerFlag = false;
    modal.dismiss('Cross-Click');
    this.deliverModal = {
      delivery: false,
      pickup: false
    }
  }

  async Complete_carrier(modal, type = 'shippment', extraChargeCheckBox = false, extraCharge = '', description = '') {
    if (type === 'shippment') {
      this.submitted = true;
      if (this.carrierForm.invalid) {
        return;
      }

      const body = {
        'trackingNo': this.carrierForm.value.connate_num,
        'freightCarrier': this.carrierForm.value.carrier_number,
        'trackUrl': ''
      }

      this.authService.updateShippingDetailsForOrder(this.userData, this.selectedOrder, body, this.isPartialShipment).then(async (resp) => {
        if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.submitted = false;
          modal.dismiss('Cross-Click');
        } else if (resp.status === 400) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.submitted = false;
          modal.dismiss('Cross-Click');
        } else {
          this.toastr.clear();
          this.toastr.info('Shipping Details Updated', 'Info', {
            positionClass: 'toast-top-center'
          });
          await this.updateToComplete(this.selectedOrder._id);
          if (this.modalName === 'InsideViewModal') {
            this.refreshSpecificOrder();
          } else {
            this.currentPage();
          }
          this.submitted = false;
          modal.dismiss('Cross-Click');
        }
      });
    } else {

      if (extraChargeCheckBox) {
        this.submitted = true;
        if (this.submitted && extraCharge.trim() === '' && description.trim() === '') {
          return;
        }
        if (this.submitted && extraCharge.trim() === '') {
          return;
        }
        if (this.submitted && description.trim() === '') {
          return;
        }
        this.partialOrderObj = {
          'orderId': this.selectedOrder._id,
          'orderedItem': this.partialShippments,
          'paymentPending': String(extraChargeCheckBox),
          'amount': extraCharge,
          'description': description
        }
      } else {
        this.submitted = true;
        if (this.carrierForm.invalid) {
          return;
        }
        this.partialOrderObj = {
          'orderId': this.selectedOrder._id,
          'orderedItem': this.partialShippments,
          'freightCarrier': this.carrierForm.value.carrier_number,
          'trackingNo': this.carrierForm.value.connate_num,
          'trackUrl': '',
          'paymentPending': String(extraChargeCheckBox)
        }
      }

      this.authService.update_partial_order(this.userData, [this.partialOrderObj]).then(async (response: any) => {
        if (response.status === 400) {
          this.toastr.clear();
          this.toastr.error(response.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (response.status === 404) {
          this.toastr.clear();
          this.toastr.error(response.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          if (response.results.length > 0) {
            if (response.results[0].success) {
              this.toastr.clear();
              this.toastr.info('Shipping Details Updated', 'Info', {
                positionClass: 'toast-top-center'
              });
              await this.updateToComplete(response.results[1].orderId);
            } else {
              this.toastr.clear();
              this.toastr.error(response.results[0].message, 'Error', {
                positionClass: 'toast-top-center'
              });
              return;
            }
          }
          this.partialOrderObj = {
            'orderId': '',
            'orderedItem': [],
            'freightCarrier': '',
            'trackingNo': '',
            'trackUrl': '',
            'paymentPending': 'true'
          }
          this.submitted = false;
          this.showForm = false;
          this.carrierForm.reset();
          if (this.modalName === 'InsideViewModal') {
            await this.refreshSpecificOrder();
          } else {
            await this.currentPage();
          }
          modal.dismiss('Cross-Click');
        }

      }).catch(err => {
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }


  async updateToComplete(orderId) {
    await this.authService.updatePartialToComplete(this.userData, {_id: orderId}).then(async (response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.toastr.clear();
        this.toastr.success('Order status updated to complete', 'Message', {
          positionClass: 'toast-top-center'
        });
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Internal Server Error', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  onEnterKey(note, noteType = 'Customer') {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = null;
      this.sendNote(note, noteType = 'Customer');
    }, 300);
  }

  sendNote(note, noteType = 'Customer') {
    if (note.value.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please add a note', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, noteType === 'Customer').then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else {
        this.toastr.clear();
        this.toastr.info('Note updated', 'Info', {
          positionClass: 'toast-top-center'
        });
        note.value = '';
        this.selectedOrder = resp;
        this.createdDivs = resp.notes.length > 0 ? resp.notes : [];
        this.createdDivs = this.createdDivs.filter((data) => {
          return typeof data !== 'number';
        });
        this.createdDivs = this.createdDivs.sort((a, b) => {
          if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
            return 1;
          } else {
            return -1;
          }
        });
        this.disablebtn = false;
        this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
        this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      }
    }).catch(er => {
      this.disablebtn = false;
    })
  }


  delete_Note(note, modal) {
    if (typeof note === 'number') {
      const temp = this.createdDivs.filter((data) => {
        return typeof note !== typeof data;
      });
      this.createdDivs = temp;
    } else {
      this.authService.deleteNote(this.userData, this.selectedOrder, note).then((response: any) => {
        if (response.status === 500) {
          this.toastr.clear();
          this.toastr.error('Note deletion failed', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.clear();
          this.toastr.success('Note deleted successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.selectedOrder = response.order;
          this.createdDivs = response.order.notes.length > 0 ? response.order.notes.sort((a, b) => {
            if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
              return 1;
            } else {
              return -1;
            }
          }) : [];
          this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
          this.customerNotes = this.createdDivs.filter(data => data.customerNote);
        }
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Note deletion failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }


  MoveToComplete(pickup: string, modal) {
    const body = [{
      propName: 'status', value: 'completed'
    }]
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('The order for pickup has been completed', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
      }

    });
    modal.dismiss('Cross-Click');
  }

  createDiv() {
    this.disablebtn = false;
    this.createdDivs.unshift(this.createdDivs.length + 1);
  }

  openPendModal(modal) {
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'})
  }

  sendMessage(value: string, modal: any) {

    if (value === '') {
      this.toastr.clear();
      this.toastr.error('please enter the message', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    const body = {
      'note': value
    }
    this.authService.add_verification_note(this.userData, this.pharmacy, body, this.selectedOrder).then((resp) => {
      this.toastr.clear();
      this.toastr.info('Verification Note added successfully', 'Info', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-Click')
    })
  }

  updateFreightCharge(updatedValue: any) {
    if (Number(updatedValue) < 0 || updatedValue === '') {
      this.toastr.clear();
      this.toastr.error('Incorrect delivery charge amount', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = [{
      propName: 'freightCharge', value: updatedValue
    }]
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.info('Delivery Charge Updated', 'Info', {
          positionClass: 'toast-top-center'
        });
        this.deliverCharge = Number(updatedValue);
        this.selectedOrder = resp.updatedBody;
        this.enable = true;
      }

    })
  }

  selectMe(me: string) {
    this.choiceMade = me;
    if (me === 'Carrier') {
      this.showAuthTPAForm = !this.showAuthTPAForm;
    } else {
      this.showAuthTPAForm = false;
    }
  }

  fillData(fullName: string, relType: string, modal: any) {
    if (fullName === '' || relType === '') {
      this.toastr.clear();
      this.toastr.error('Please fill the Details', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.myorderObj = {...this.myorderObj, ['relationship_with_customer']: relType};
    this.myorderObj = {...this.myorderObj, ['pickup_name']: fullName};
    modal.dismiss('Cross-Click');
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }

  validateRepeats(value: string) {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(value)) {
      this.validate_repeat = true;
    } else {
      this.validate_repeat = false;
    }
  }

  closeDispenseModal(modal: any) {
    this.rows.filter((order) => order.checked = false)
    this.downloadAllCheck = false;
    this.downloadOrders = [];
    this.shippedOrders = [];
    this.tempRecordArray.map((data) => {
      return data.orderedItem = [];
    });
    modal.dismiss('Cross-Click');
  }


  openIssueCategoryModal(modal, isOrderData = 'orderData') {
    this.showForm = false;
    this.SelectedIssueCategory = null;
    this.isOrderData = isOrderData;
    this.submitted = false;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  reportIssue(value: string, message: string) {

    let body = [];
    if (value === 'true') {
      if (this.SelectedIssueCategory === '') {
        this.toastr.clear();
        this.toastr.error('Please Select the Issue Category', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      body = [{'propName': 'issueReported', 'value': value}, {'propName': 'issueReportedId', 'value': this.SelectedIssueCategory}]
    } else {
      body = [{'propName': 'issueReported', 'value': value}];
    }
    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });

      } else if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success(message, 'Success', {
          positionClass: 'toast-top-center'
        });
        this.fetchOrders_Issues();
        this.currentPage();
        this.modalService.dismissAll();
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Failed to update the order', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  fetchOrders_Issues() {
    this.globalOrderStatus = 'issues'
    this.prev = 'issues';
    this.changeColor = !this.changeColor;
    this.page1 = 1;
    this.selectedCategory = 'All';
    if (this.search.value !== '') {
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page1, this.globalOrderStatus).then((response: any) => {
        this.maxPageSize = response.totalPages;
        this.rows = response.results.filter((data) => {
          return data.issueReported;
        });
        if (this.rows.length === 0) {
          if (this.globalOrderStatus === 'new') {
            this.message = 'There are currently no new orders to process'
          } else if (this.globalOrderStatus === 'pending') {
            this.message = 'There are currently no orders pending';
          } else if (this.globalOrderStatus === 'paymentProcess') {
            this.message = 'There are currently no orders available for payment processing';
          } else if (this.globalOrderStatus === 'dispense') {
            this.message = 'There are currently no orders available for dispensing';
          } else if (this.globalOrderStatus === 'shipped') {
            this.message = 'There are presently no orders available for shipping';
          } else if (this.globalOrderStatus === 'completed') {
            this.message = 'There are currently no orders marked as completed';
          } else if (this.globalOrderStatus === 'cancelled') {
            this.message = 'There are currently no orders that have been canceled';
          } else if (this.globalOrderStatus === 'refund') {
            this.message = 'There are currently no orders in refund';
          } else if (this.globalOrderStatus === 'issues') {
            this.message = 'There are currently no orders in issues';
          }
        }
        this.rows.sort((a, b) => {
          if (moment(moment(a.orderDate).format(), 'L').isBefore(moment(moment(b.orderDate).format(), 'L'))
            && moment(moment(a.orderDate).format(), 'LT').isBefore(moment(moment(b.orderDate).format(), 'LT'))) {
            return -1;
          } else {
            return 1;
          }
        });
      });
    } else {
      this.authService.fetchIssuedOrders(this.userData, this.pharmacy, this.page1).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        }
        if (resp.status === 500) {
          this.rows = [];
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.rows = resp.results;
          this.downloadOrders = resp.results;
          this.maxPageSize = resp.totalPages;
          if (this.rows.length === 0) {
            this.message = 'There are currently no issues reported';
          }
        }
      });
    }

  }

  paste(url, modal) {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (url === '') {
      this.toastr.clear();
      this.toastr.error('Please Enter ScriptImage URL', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(url) ? true : false;
    if (!pattern.test(url)) {
      this.toastr.clear();
      this.toastr.error('URL is invalid', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    // const a = document.createElement('a');
    // a.href = url;
    // a.target = '_blank';
    // a.click();

    if (this.scriptImage_for === 'for_each_medicine') {
      this.orderedItems[this.selectedMedicine_Index].scriptData.uploadedScript = url;
      this.orderedItems[this.selectedMedicine_Index].scriptData.type = this.uploadType;
    } else {
      this.scriptImage = url;
    }
    this.toastr.clear();
    this.toastr.success('URL Inserted', 'Success', {
      positionClass: 'toast-top-center'
    });
    modal.dismiss('Cross-Click');
  }

  OnChangeUploadType(event) {
    this.uploadType = event.target.value;
  }

  OnChangeOrder(event) {
    if (event.target.value === 'issues') {
      this.fetchOrders_Issues();
    } else {
      this.filter(event.target.value);
    }
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  OnChangeToken(Item, data, type, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((product) => {
        if (Item._id === product._id) {
          product.valid = true;
        }
      })
      if (this.orderedItems.length > 0) {
        if (type === 'token') {
          if (this.orderedItems.length > 0) {
            this.orderedItems.map((item) => {
              if (Item.productName === item.product_name) {
                item.token = data.value;
              }
            })
          }
        } else {
          if (this.orderedItems.length > 0) {
            this.orderedItems.map((item) => {
              if (Item.productName === item.product_name) {
                item.repeats = data.value;
              }
            })
          }
        }
      }
    } else {
      if (this.orderedItems.length > 0) {
        if (type === 'token') {
          if (this.orderedItems.length > 0) {
            this.orderedItems.map((item) => {
              if (Item.product_name === item.product_name) {
                item.token = data.value;
              }
            })
          }
          this.Medicinerows.map((item) => {
            if (Item.product_name === item.productName) {
              item.token = data.value;
            }
          });
        } else {
          if (this.orderedItems.length > 0) {
            this.orderedItems.map((item) => {
              if (Item.product_name === item.product_name) {
                item.repeats = data.value;
              }
            });
            this.Medicinerows.map((item) => {
              if (Item.product_name === item.productName) {
                item.repeats = data.value;
              }
            });
          }
        }
      }
    }


  }


  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  nextStep(wizard: WizardComponent) {
    this.wizard = wizard;
    this.isCollapsed = true;
    const check = this.orderedItems.some((data) => {
      return data.token === '' || data.repeats === 0 || data.repeats === '';
    });
    if (check) {
      this.toastr.clear();
      this.toastr.error('Please fill the token and repeats for the selected Products', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      this.wizard.goToNextStep();
    }
  }

  OnSearchBy(event) {
    this.search = this.searchBy.find((data: any) => {
      return data.value === event.target.value;
    });
  }

  OnChangeSearch(checkBoxChange) {
    if (checkBoxChange.checked) {
      this.search.type = 'filter'
      this.checkboxStatus = checkBoxChange.checked;
    } else {
      this.checkboxStatus = checkBoxChange.checked
      this.search.type = 'order';
      this.searchValue = '';
      this.globalOrderStatus = this.prev;
      document.querySelector('input').value = '';
      if (this.globalOrderStatus === 'issues') {
        this.fetchOrders_Issues();
      } else {
        this.currentPage();
      }
    }
  }

  OnFilterIssues(category) {
    this.selectedCategory = category.categoryName;
    this.authService.list_reported_issue_on_filter(this.userData, category._id).then((response: any) => {
      this.rows = response.results;
      this.maxPageSize = response.totalPages;
    })
  }

  createIssueCategory(categoryName) {
    this.submitted = true;
    if (categoryName.value.trim() === '') {
      return;
    }
    const body = {
      'categoryName': categoryName.value.trim(),
      'pharmacyId': localStorage.getItem('pharmacyId'),
      'platformId': this.platform._id
    }
    this.authService.create_OrderIssues_category(this.userData, body).then((response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Issue Category Created Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.fetchOrderIssues_category();
        this.showForm = false;
        this.submitted = false;
      }
    }).catch(err => {

    });
  }

  deleteIssueCategory(modal) {
    this.authService.delete_OrderIssues_category(this.userData, this.categorySelected).then((response: any) => {
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Issue Category Deleted Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.SelectedIssueCategory = null;
        this.fetchOrderIssues_category();
        modal.dismiss('Cross-Click');
      }
    });
  }

  openDeleteModal(modal, category) {
    this.categorySelected = category;
    this.confirmationMessage = 'Do you want to delete this category?';
    this.modalService.open(modal, {size: 'md', backdrop: 'static'})
  }

  OnChangeCategory(category) {
    this.SelectedIssueCategory = category._id;
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  pagination(pagenum: number) {
    this.page1 = pagenum;
    if (this.searchValue === '' && this.globalOrderStatus !== '') {
      if (this.globalOrderStatus === 'issues') {
        this.authService.fetchIssuedOrders(this.userData, this.pharmacy, this.page1).then((resp: any) => {
          if (resp.status === 403) {
            this.toastr.clear();
            this.toastr.error(resp.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
          }
          if (resp.status === 500) {
            this.rows = [];
            this.toastr.clear();
            this.toastr.error(resp.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
          } else {
            this.rows = resp.results;
            this.downloadOrders = resp.results;
            this.maxPageSize = resp.totalPages;
            if (this.rows.length === 0) {
              this.message = 'There are currently no issues reported';
            }
          }
        });
      } else {
        this.authService.fetchOrders(this.userData, this.globalOrderStatus, this.page1, localStorage.getItem('pharmacyId')).then((resp: any) => {
          this.rows = resp.results;
          this.maxPageSize = resp.totalPages;
          this.downloadOrders = this.rows;
          if (this.rows.length === 0) {
            this.message = 'There are currently no new orders to process';
            this.spinner.hide();
          }
          this.rows.sort((a, b) => {
            const dateA = moment(a.orderDate);
            const dateB = moment(b.orderDate);

            if (dateA.isBefore(dateB)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          });

          this.rows.map(order => {
            const checkBalance = order.orderedItem.some(item => {
              return item.remainingQuantity > 0 && item.remainingQuantity !== undefined;
            });
            if (checkBalance) {
              order.showPartialShipBtn = true
            } else {
              order.showPartialShipBtn = false;
            }
          });
          this.tempOrders = this.rows;
          if (this.globalOrderStatus === 'pendingShipment' && this.selectedCategory !== '' && this.selectedCategory !== 'Partially Shipped' && this.selectedCategory !== 'All' && this.selectedCategory !== 'Filter') {
            this.filterPendingShipment('pickup');
          }
        });
      }
    } else {
      // this.globalOrderStatus = !this.checkboxStatus ? 'new' : this.checkboxStatus && this.globalOrderStatus === 'new' ? 'new' : this.globalOrderStatus;
      if (this.checkboxStatus && this.search.type === 'filter') {
        this.globalOrderStatus = '';
      }
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page1,
        this.globalOrderStatus === '' ? '' : this.globalOrderStatus).then((response: any) => {
        if (response.status === 403) {
          this.toastr.clear();
          this.toastr.error(response.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.maxPageSize = response.totalPages;
          if (this.checkboxStatus) {
            this.rows = response.results.filter(data => data.status !== 'preOrder');
          } else {
            this.rows = response.results.filter(data => data.status !== 'preOrder' && !data.issueReported);
          }
        }
      });
    }
  }


  openExtraChargeModal(content) {
    this.submitted = false;
    this.disablebtn = false;
    this.modalService.open(content, {size: 'md', backdrop: 'static'});
  }

  validateAmount(extraCharge) {
    const pattern = /^[0-9.]+$/;
    if (!pattern.test(extraCharge.value.trim())) {
      this.validation = true;
      return;
    }
    this.validation = false;
  }

  makeExtraCharge(extraCharge, description, modal) {
    this.submitted = true;
    if (this.submitted && extraCharge.value.trim() === '' && description.value.trim() === '') {
      return;
    }
    if (this.submitted && extraCharge.value.trim() === '') {
      return;
    }
    if (this.submitted && description.value.trim() === '') {
      return;
    }

    const body = {
      amount: Number(extraCharge.value),
      description: description.value
    }
    this.disablebtn = true;
    this.authService.additionalCostforOrder(this.userData, body, this.selectedOrder).then((response: any) => {
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else {
        this.toastr.clear();
        this.toastr.success('Additional Cost Updated', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.authService.getOrder_by_Id(this.userData, this.selectedOrder._id).then(async (response: any) => {
          this.selectedOrder = response;
        });
        this.disablebtn = false;
        this.submitted = false;
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Internal Server Error', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.disablebtn = false;
    })
  }

  // updateItemQuantityBox(item, shippedQuantity) {
  //   this.orderedItems.map((data) => {
  //     if (item.product_name === data.product_name) {
  //       if (this.selectedOrder.partialShipment) {
  //         data.tempbalance = data.balance;
  //       } else {
  //         data.tempbalance = data.balance;
  //       }
  //     }
  //   });
  //   const temp = this.partialShippments.filter((data) => {
  //     return data.token !== item.token;
  //   });
  //   this.partialShippments = temp;
  // }

  updateItemQuantity(item, shippedQuantity) {
    let object = {
      'token': '',
      'requestedQuantity': '1'
    }
    if (shippedQuantity.value !== '') {
      if (!this.selectedOrder.partialShipment) {
        if (Number(shippedQuantity.value) <= 0 || Number(shippedQuantity.value) > item.balance) {
          this.toastr.clear();
          this.toastr.error('Requested quantity out of range', 'Error', {
            positionClass: 'toast-top-center'
          });
          const temp = this.partialShippments.filter(data => data.token !== item.token);
          this.partialShippments = temp;
          this.orderedItems.map((data) => {
            if (item.product_name === data.product_name) {
              if (this.selectedOrder.partialShipment) {
                data.tempbalance = item.quantity;
              } else {
                data.tempbalance = item.balance;
              }
            }
          });
          shippedQuantity.value = '';
          return;
        }
      } else {
        if (Number(shippedQuantity.value) <= 0 || Number(shippedQuantity.value) > item.balance) {
          this.toastr.clear();
          this.toastr.error('Requested quantity out of range', 'Error', {
            positionClass: 'toast-top-center'
          });
          const temp = this.partialShippments.filter(data => data.token !== item.token);
          this.partialShippments = temp;
          this.orderedItems.map((data) => {
            if (item.product_name === data.product_name) {
              if (this.selectedOrder.partialShipment) {
                data.tempbalance = item.remainingQuantity;
              } else {
                data.tempbalance = item.balance;
              }
            }
          });
          shippedQuantity.value = '';
          return;
        }
      }
    }
    if (this.partialShippments.length > 0) {
      if (shippedQuantity.value === '') {
        const temp = this.partialShippments.filter(data => data.token !== item.token);
        this.partialShippments = temp;
        this.orderedItems.map((data) => {
          if (item.product_name === data.product_name) {
            if (this.selectedOrder.partialShipment) {
              data.tempbalance = item.quantity;
            } else {
              data.tempbalance = item.balance;
            }
          }
        });
      } else {
        object.token = item.token;
        object.requestedQuantity = shippedQuantity.value;
        const check = this.partialShippments.some(data => data.token === object.token);
        if (check) {
          this.orderedItems.map((data) => {
            if (item.product_name === data.product_name) {
              if (this.selectedOrder.partialShipment) {
                data.tempbalance = data.quantity - Number(shippedQuantity.value);
              } else {
                data.tempbalance = data.balance - Number(shippedQuantity.value);
              }
            }
          });
          this.partialShippments.map((itemData) => {
            if (itemData.token === object.token) {
              itemData.requestedQuantity = object.requestedQuantity;
            }
          })
        } else {
          this.orderedItems.map((data) => {
            if (item.product_name === data.product_name) {
              if (this.selectedOrder.partialShipment) {
                data.tempbalance = data.quantity - Number(shippedQuantity.value);
              } else {
                data.tempbalance = data.balance - Number(shippedQuantity.value);
              }
            }
          });
          this.partialShippments.push(object);
        }
        this.sendPartialShippment();
      }
    } else {
      if (shippedQuantity.value !== '') {
        object.token = item.token;
        object.requestedQuantity = shippedQuantity.value;
        this.orderedItems.map((data) => {
          if (item.product_name === data.product_name) {
            if (this.selectedOrder.partialShipment) {
              data.tempbalance = data.balance - Number(shippedQuantity.value);
            } else {
              data.tempbalance = data.balance - Number(shippedQuantity.value);
            }
          }
        });
        this.partialShippments.push(object);
      }
      this.sendPartialShippment();
    }

  }

  sendPartialShippment() {
    if (this.partialShippments.length === 0) {
      this.showForm = true;
      this.toastr.clear();
      this.toastr.error('Select the product for partial shipment', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.showForm = true;
    this.carrierForm.reset();
    this.submitted = false;
    this.partialOrderObj = {
      'orderId': this.selectedOrder._id,
      'orderedItem': this.partialShippments,
      'freightCarrier': '',
      'trackingNo': '',
      'trackUrl': '',
      'paymentPending': 'true'
    }
  }

  updateOrderToComplete(modal) {
    this.authService.updatePartialToComplete(this.userData, this.selectedOrder).then((response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.toastr.clear();
        this.toastr.success('Order status updated to complete', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Internal Server Error', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  filterPendingShipment(status: string) {
    this.filter('pendingShipment');
  }

}

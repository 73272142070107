import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct, NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Order} from '../orders/order';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';
// @ts-ignore
const moment = require('moment');
const momentTimezone = require('moment-timezone');

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss']
})
export class OrdersHistoryComponent implements OnInit {

  showcalendar = false;
  date = new Date();
  userData: any;
  pharmacy: any;
  rows: any[] = [];
  todayDay: any;
  formattedDate: any;
  dateSearch = new Date();
  page = 1;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  pharmacyUser: any;
  customerNotes = [];
  pharmacyNotes = [];
  toDate: NgbDate | null;

  from: string;
  to: string;
  isCollapsed = true;
  selectedOrder: Order | any;
  pharmacyUserStatus = '';
  createdDivs: any[] = [];
  orderedItems: any[] = [];
  closeResult = '';
  showUrl = false;
  globalOrderStatus = '';
  platform: any;
  selectedPharmacy: any;
  totalQuantity = 0;
  message = 'There are no orders currently';
  searchBy = [
    {title: 'Name', value: 'customerName', type: 'filter'},
    {title: 'Phone Number', value: 'customerNumber', type: 'filter'},
    {title: 'Email', value: 'customerEmail', type: 'filter'},
    {title: 'Order No', value: 'orderNumber', type: 'filter'}
  ];
  search = {title: 'Name', value: 'customerName', type: 'order'};
  searchValue = '';
  viewScript = '';
  disablebtn = false;
  maxPageSize = 0;
  childOrder: any;
  expand = false;
  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe, private router: Router, private clipboard: Clipboard,
              private modalService: NgbModal, private downloadFile: DownloadFileService,
              private spinner: NgxSpinnerService, private storage: LocalStorageService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.todayDay = moment(new Date()).format('YYYY-MM-DD');
    this.from = moment(new Date()).format('YYYY-MM-DD');
    this.to = moment(new Date()).format('YYYY-MM-DD');
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.formattedDate = momentTimezone(this.monthPipe.transform(this.dateSearch)).tz('asia/kolkata').format();
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        sessionStorage.setItem('pharmacyId', data);
        this.loadingScreen();
        this.todaysOrder();
        this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
          this.selectedPharmacy = resp;
        });
      }
      return;
    });
    this.todaysOrder();
    this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
      this.selectedPharmacy = resp;
    });
  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }


  todaysOrder() {
    this.authService.fetchOrders_by_date(this.userData, moment(new Date()).format(), moment(new Date()).format(), 1).then((resp: any) => {
      if (resp.status === 403) {
        this.spinner.hide();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.spinner.hide();
      this.rows = resp.results;
      this.maxPageSize = resp.totalPages;
    });
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  OnSearchBy(event) {
    this.search = this.searchBy.find((data: any) => {
      return data.value === event.target.value;
    });
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.toDate !== undefined && this.toDate !== null) {
      const x = moment(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day).format('YYYY-MM-DD');
      const {day, month, year} = this.toDate;
      const y = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
      this.from = x;
      this.to = y;
      this.authService.fetchOrders_by_date(this.userData, x,
        y, this.page, this.globalOrderStatus).then((resp: any) => {
        if (resp.results.length === 0) {
          this.message = 'There are no orders currently';
        }
        this.rows = resp.results;
        this.maxPageSize = resp.totalPages;

      }).catch(err => {
        console.log(err)
      });
    }
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }


  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }


  pagination(page: number) {
    this.page = page;
    if (this.searchValue === '') {
      this.authService.fetchOrders_by_date(this.userData, this.from, this.to, this.page, this.globalOrderStatus === 'All' ? '' : this.globalOrderStatus).then((resp: any) => {
        this.rows = resp.results;
        this.maxPageSize = resp.totalPages;
      });
    } else {
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page, this.globalOrderStatus, false).then((response: any) => {
        this.rows = response.results;
        this.maxPageSize = response.totalPages;
      });
    }
  }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const activeElement = document.activeElement;
    const isInputField = activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA'
    );

    if (!isInputField && (event.key === 'x' || event.key === 'X')) {
      this.modalService.dismissAll('x-key-press');
    }
  }

  viewOrder(order: any, content) {
    this.customerNotes = [];
    this.expand = false;
    this.pharmacyNotes = []
    this.isCollapsed = true;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.pharmacyUserStatus = response.userId.pharmacyUserStatus;
      this.pharmacyUser = response.userId;
      this.createdDivs = this.selectedOrder.notes.length > 0 ? this.selectedOrder.notes.sort((a, b) => {
        if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
          return 1;
        } else {
          return -1;
        }
      }) : [];
      this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
      this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      this.createdDivs.map((data) => {
        data.disabled = true;
        return data;
      });
      this.orderedItems = response.orderedItem;
      this.selectedOrder.totalCost = 0;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      response.orderedItem.map((data) => {
        this.selectedOrder.totalCost += data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      });
      await this.modalService.open(content, {windowClass: 'full-screen-modal', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });

  }

  filter(status) {
    if (this.search.type === 'filter') {
      this.globalOrderStatus = status;
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, this.page, '', false)
        .then((response: any) => {
          this.rows = response.results.filter(order => order.status === status);
        });
    } else {
      this.search.type = 'order';
      this.globalOrderStatus = status;
      this.authService.fetchOrders_by_date(this.userData, this.from, this.to, this.page, status).then((resp: any) => {
        this.rows = resp.results;
        this.maxPageSize = resp.totalPages;
        if (this.rows.length === 0) {
          if (status === 'completed') {
            this.message = 'There are currently no orders marked as completed';
          } else if (status === 'cancelled') {
            this.message = 'There are currently no orders that have been canceled';
          }
        }
      });
    }
  }

  searchOrder(value) {
    this.search.type = 'filter';
    if (value.trim() === '') {
      this.toastr.error('Enter ' + this.search.title, 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.search.title === 'Phone Number') {
      this.globalOrderStatus = ''
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus, false)
        .then((response: any) => {
          this.rows = response.results;
          this.maxPageSize = response.totalPages;
          if (this.rows.length === 0) {
            this.toastr.error('Orders not found', 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
        });
    } else if (this.search.title === 'Order No') {
      this.globalOrderStatus = ''
      const pattern = /\d/;
      if (!pattern.test(value)) {
        this.toastr.error('Invalid ' + this.search.title, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.searchValue = value;
        this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus, false).then((response: any) => {
          this.rows = response.results;
          this.maxPageSize = response.totalPages;
          if (this.rows.length === 0) {
            this.toastr.error('Orders not found', 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
        })
      }
    } else if (this.search.title === 'Email') {
      this.globalOrderStatus = ''
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus, false).then((response: any) => {
        this.rows = response.results;
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    } else {
      this.searchValue = value;
      this.authService.searchOrder(this.userData, this.selectedPharmacy, this.search.value, this.searchValue, 1, this.globalOrderStatus, false).then((response: any) => {
        this.rows = response.results;
        this.maxPageSize = response.totalPages;
        if (this.rows.length === 0) {
          this.toastr.error('Orders not found', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }
  }


  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }

  allOrders(status) {
    this.searchValue = '';
    this.globalOrderStatus = status;
    this.authService.fetchOrders_by_date(this.userData, this.from, this.to, 1).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.rows = resp.results;
      this.maxPageSize = resp.totalPages;
    }).catch(err => {
      console.log(err)
    });
  }

  createDiv() {
    this.disablebtn = false;
    this.createdDivs.unshift(this.createdDivs.length + 1);
  }

  sendNote(note: HTMLInputElement, noteType = 'Customer') {
    if (note.value.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please add a note', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.disablebtn = true;
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, noteType === 'Customer').then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else {
        this.toastr.clear();
        this.toastr.info('Note updated', 'Info', {
          positionClass: 'toast-top-center'
        });
        note.value = '';
        this.selectedOrder = resp;
        this.createdDivs = resp.notes.length > 0 ? resp.notes : [];
        this.createdDivs = this.createdDivs.filter((data) => {
          return typeof data !== 'number';
        });
        this.createdDivs = this.createdDivs.sort((a, b) => {
          if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
            return 1;
          } else {
            return -1;
          }
        });
        this.disablebtn = false;
        this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
        this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      }
    }).catch(er => {
      this.disablebtn = false;
    })
  }

  delete_Note(note, modal) {
    if (typeof note === 'number') {
      const temp = this.createdDivs.filter((data) => {
        return typeof note !== typeof data;
      });
      this.createdDivs = temp;
    } else {
      this.authService.deleteNote(this.userData, this.selectedOrder, note).then((response: any) => {
        if (response.status === 500) {
          this.toastr.clear();
          this.toastr.error('Note deletion failed', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.clear();
          this.toastr.success('Note deleted successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.selectedOrder = response.order;
          this.createdDivs = response.order.notes.length > 0 ? response.order.notes.sort((a, b) => {
            if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
              return 1;
            } else {
              return -1;
            }
          }) : [];
        }
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Note deletion failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, this.selectedPharmacy, 'orderNumber', orderNo, 1, this.globalOrderStatus).then(async (response: any) => {
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'max', backdrop: 'static'});
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../shared/auth/auth.service';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})

export class ResetPasswordPageComponent implements OnInit {

  platform: any;
  userData: any;
  mismatch = false;
  OtpSubmit = false;
  visible = false;
  showpwd = false;
  showConfirmPassword = false;
  submitted = false;
  isSequential = false;
  resetPassowordForm = new FormGroup({
    new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    confirm_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)])
  });
  loginFormSubmitted = false;

  forgotpassWrdForm = new FormGroup({
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  });
  OtpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
    new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    confirm_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)])
  });

  constructor(private activatedRoute: ActivatedRoute, private toastr: ToastrService, private authService: AuthService,
              private router: Router, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.activatedRoute.queryParams.subscribe((resp: any) => {
      if (Object.keys(resp).length > 0) {
        this.userData = resp;
      } else {
        this.router.navigate(['/pages/error']);
      }
    });
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  ChangePassword() {
    this.submitted = true;
    if (this.resetPassowordForm.invalid) {
      return;
    }
    if (this.resetPassowordForm.value.new_password !== this.resetPassowordForm.value.confirm_password) {
      this.mismatch = true;
      this.submitted = false;
      return;
    }
    if (this.hasSequentialCharacters(this.resetPassowordForm.value.new_password)) {
      this.isSequential = true;
      return;
    }
    this.isSequential = false;
    this.mismatch = false;
    const body = {
      'userId': this.userData.uid,
      'email': this.userData.email === 'true' ? true : false,
      'otp': this.userData.uat,
      newPassword: this.resetPassowordForm.value.new_password,
      confirmPassword: this.resetPassowordForm.value.confirm_password,
    }
    this.authService.changePassword(body, this.platform).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Successs', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Password Changed SuccessFully', 'Successs', {
          positionClass: 'toast-top-center'
        });
        this.submitted = false;
        this.router.navigate(['/pages/login']);
      }
    }).catch((err) => {
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  onSubmit() {
    this.loginFormSubmitted = true;
    if (this.forgotpassWrdForm.invalid) {
      return;
    }
    const body = {
      number: '+61' + this.forgotpassWrdForm.value.number
    }
    this.authService.forgotpassword(body, this.platform).then((resp: any) => {
      this.userData = resp;
      this.loginFormSubmitted = false;

    }).catch((err) => {
    });
    this.visible = true;
  }

  onSubmitOTP() {
    this.OtpSubmit = true;
    if (this.OtpForm.invalid) {
      return;
    }
    if (this.OtpForm.value.new_password !== this.OtpForm.value.confirm_password) {
      this.mismatch = true;
      this.OtpSubmit = false;
      return;
    }
    if (this.hasSequentialCharacters(this.resetPassowordForm.value.new_password)) {
      this.isSequential = true;
      return;
    }
    this.isSequential = false;
    this.mismatch = false;
    const body = {
      number: '+61' + this.forgotpassWrdForm.value.number,
      otp: this.OtpForm.value.otp,
      newPassword: this.OtpForm.value.new_password,
      confirmPassword: this.OtpForm.value.confirm_password,
      email: false,
      'userId': this.userData.userId,
    }
    this.authService.changePassword(body, this.platform).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Successs', {
          positionClass: 'toast-top-center'
        });

      } else {
        this.toastr.success('Password Changed SuccessFully', 'Successs', {
          positionClass: 'toast-top-center'
        });
        this.OtpSubmit = false;
        this.router.navigate(['/pages/login']);
      }
    }).catch((err) => {
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    });
    this.OtpSubmit = false;
  }

  hasSequentialCharacters(password) {
    const length = password.length;
    // Loop through the password to check for sequential letters and numbers
    for (let i = 0; i < length - 1; i++) {
      const currentChar = password.charCodeAt(i);
      // Check for ascending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar + 1) {
        return true;
      }
      // Check for descending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar - 1) {
        return true;
      }

      // Check if the password is repeated
      if (password.charAt(i + 1) === password.charAt(i)) {
        return true;
      }
    }
    return false;
  }
}

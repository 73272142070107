import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/auth/auth.service';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';
import {ActivatedRoute} from '@angular/router';
import {Script} from './Script';

@Component({
  selector: 'app-erx-prescription-page',
  templateUrl: './erx-prescription-page.component.html',
  styleUrls: ['./erx-prescription-page.component.scss']
})
export class ErxPrescriptionPageComponent implements OnInit {

  userData: any;
  scriptId = ''
  ScriptRows: Script;
  tokenData = [];
  name = '';
  productNames = [];

  constructor(private authService: AuthService, private storage: LocalStorageService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });

    this.scriptId = window.location.pathname.split('/myscripts/')[1]
    this.authService.view_Script(this.userData, this.scriptId).then((objectResp: any) => {
      this.productNames = [];
      this.tokenData = objectResp;
      this.name = objectResp.firstName.charAt(0) + objectResp.lastName.charAt(0);
      this.ScriptRows = objectResp.medicationObject[0];
      objectResp.medicationObject.forEach((data) =>{
        this.productNames.push(data.product_name);
      })
    })
  }

}
